@import '../../App.scss';

.compare-floorplans-select-floorplan-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    height: 100%;
    .compare-floorplans-select-floorplan {
        width: 256px;
        height: 256px;
        background: $magenta;
        color: $white;
        text-transform: uppercase;
        font-family: $avenir-book;
        font-size: 13px;
        line-height: calc(18 / 13);
        letter-spacing: 0.87px;
        padding: 52px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        box-sizing: border-box;
        text-align: center;
        transition: all 0.25s;
        @include cubic;
        border: 1px solid $magenta;
        box-sizing: border-box;
        &:hover {
            border: 1px solid $magenta;
            background: $white;
            color: $magenta;
            svg * {
                fill: $magenta;
            }
        }
        svg {
            margin-bottom: 24px;
            transition: all 0.25s;
            @include cubic;
        }
    }
    .compare-floorplans-left-flowers {
        position: absolute;
        left: 0;
        bottom: 0;
        width: calc((100% - 256px - 80px) / 2);
        @media (max-width: 1200px) {
            display: none;
        }
    }
    .compare-floorplans-right-flowers {
        position: absolute;
        right: 0;
        bottom: 0;
        width: calc((100% - 256px - 80px) / 2);
        @media (max-width: 1200px) {
            display: none;
        }
    }
}
