/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html,
body,
#root {
    height: 100%;
    overflow-x: hidden;
}
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
}
body {
    line-height: 1;
}
ol,
ul {
    list-style: none;
    margin: 0;
    padding: 0;
}
blockquote,
q {
    quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
    content: '';
    content: none;
}
table {
    border-collapse: collapse;
    border-spacing: 0;
}

/* Global styling stuff */

a {
    text-decoration: none;
}
p {
    margin-bottom: 24px;
}
button {
    outline: none;
    border: none;
    cursor: pointer;
}
::-webkit-scrollbar {
    width: 8px; /* for vertical scrollbars */
    height: 8px; /* for horizontal scrollbars */
}
::-webkit-scrollbar-track {
    background: #e0d9ce;
}
::-webkit-scrollbar-thumb {
    background: #a39987;
}

.clearfix::after {
    content: '';
    clear: both;
    display: table;
}

sup {
    vertical-align: super;
    font-size: 0.66em;
    margin-left: 0.25em;
}

@font-face {
    font-family: 'Avenir-Book';
    src: url('/fonts/Avenir-Book.ttf') format('truetype');
}

@font-face {
    font-family: 'Avenir-Medium';
    src: url('/fonts/Avenir-Medium.ttf') format('truetype');
}

@font-face {
    font-family: 'Avenir-Heavy';
    src: url('/fonts/Avenir-Heavy.ttf') format('truetype');
}

@font-face {
    font-family: 'Avenir-Roman';
    src: url('/fonts/Avenir-Roman.ttf') format('truetype');
}

@font-face {
    font-family: 'Avenir-BookOblique';
    src: url('/fonts/Avenir-BookOblique.ttf') format('truetype');
}
