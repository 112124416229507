@import '../../App.scss';

.hamburger {
    padding: 16px 24px;
    display: inline-block;
    cursor: pointer;
    transition-property: opacity, filter;
    transition-duration: 0.25s;
    @include cubic;
    font: inherit;
    color: inherit;
    text-transform: none;
    background-color: transparent;
    border: 0;
    margin: 0 !important;
    overflow: visible;
}
.hamburger:hover {
    opacity: 0.7;
}
.hamburger.is-active:hover {
    opacity: 0.7;
}
.hamburger.is-active .hamburger-inner,
.hamburger.is-active .hamburger-inner::before,
.hamburger.is-active .hamburger-inner::after {
    background-color: $white;
}

.hamburger-box {
    width: 24px;
    height: 24px;
    display: inline-block;
    position: relative;
}

.hamburger-inner {
    display: block;
    top: 50%;
    margin-top: -2px;
}
.hamburger-inner,
.hamburger-inner::before,
.hamburger-inner::after {
    width: 24px;
    height: 1px;
    background-color: $white;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.25s;
    @include cubic;
}
.hamburger-inner::before,
.hamburger-inner::after {
    content: '';
    display: block;
}
.hamburger-inner::before {
    top: -10px;
}
.hamburger-inner::after {
    bottom: -10px;
}
.hamburger--slider .hamburger-inner {
    top: 4px;
}
.hamburger--slider .hamburger-inner::before {
    top: 10px;
    transition-property: transform, opacity;
    @include cubic;
    transition-duration: 0.25s;
}
.hamburger--slider .hamburger-inner::after {
    top: 20px;
}

.hamburger--slider.is-active .hamburger-inner {
    transform: translate3d(0, 10px, 0) rotate(45deg);
}
.hamburger--slider.is-active .hamburger-inner::before {
    transform: rotate(-45deg) translate3d(-5.71429px, -6px, 0);
    opacity: 0;
}
.hamburger--slider.is-active .hamburger-inner::after {
    transform: translate3d(0, -20px, 0) rotate(-90deg);
}
