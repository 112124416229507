@import '../../App.scss';

// Local Variables

$header-offset: 80px;
$mobile-header-offset: 56px;

.privacy-policy {
    background: #f9faf1;
    padding-top: $header-offset;
    min-height: calc(100vh - #{$header-offset});
    font-family: $avenir-book;
    font-size: 16px;
    line-height: 1.6;
    padding-bottom: 40px;
    .pp-container {
        max-width: 800px;
        margin: 40px auto;
        padding: 0 24px;
    }
    p {
        margin-bottom: 16px;
    }
    @media screen and (max-width: 1300px) {
        padding-top: $mobile-header-offset;
        min-height: calc(100vh - #{$mobile-header-offset});
    }
    h2 {
        font-size: 40px;
        margin-bottom: 16px;
        line-height: 1.2;
    }
    h3 {
        font-size: 24px;
        margin-bottom: 16px;
    }
    ul {
        margin-bottom: 16px;
        list-style-type: disc;
        padding-left: 16px;
        li {
            margin-bottom: 4px;
        }
    }
}
