@import '../../App.scss';

.search-header-dropdown-container {
    border-right: 1px solid #465c54;
    height: 32px;
    display: flex;
    align-items: center;
    position: relative;
    &.active-dropdown {
        .down-arrow {
            transform: scaleY(-1);
        }
    }
    .button {
        font-family: $avenir-book;
        text-transform: uppercase;
        font-size: 13px;
        letter-spacing: 0.87px;
        color: $white;
        background: transparent;
        height: 100%;
        display: flex;
        align-items: center;
        transition: all 0.25s;
        @include cubic;
        padding: 0 56px;
        cursor: pointer;

        svg {
            transition: all 0.25s;
            @include cubic;
        }

        .down-arrow-path {
            transition: all 0.25s;
            @include cubic;
        }
        &:hover {
            color: #c9d4d1;
            svg {
                fill: #c9d4d1;
                .down-arrow-path {
                    stroke: #c9d4d1;
                }
            }
        }
    }
    svg {
        fill: $white;
        height: 16px;
        width: 24px;
        margin-right: 24px;
        position: relative;
        top: -1px;
        &.block-icon {
            fill-opacity: 0;
            stroke: #fff;
        }
        &.down-arrow {
            margin-right: 0;
            margin-left: 24px;
            width: 12px;
        }
    }
    .down-arrow {
        transition: all 0.25s;
        @include cubic;
    }
    .dropdown {
        position: absolute;
        // height: 0;
        top: 48px;
        left: 0;
        width: auto;
        min-width: 100%;
        background: $lighter-green;
        box-sizing: border-box;
        color: $white;
        transition: height 0.25s;
        overflow: hidden;
        @include cubic;
        li {
            &:first-of-type {
                margin-top: 32px;
            }
            &:last-of-type {
                margin-bottom: 32px;
            }
        }
    }
    .dropdown-left {
        display: flex;
        align-items: center;
    }
}
