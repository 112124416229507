@import '../../App.scss';

.list-item {
    position: relative;
    margin-bottom: 24px;
    font-family: $avenir-book;
    -webkit-column-break-inside: avoid;
    .number {
        position: absolute;
        top: 0;
        left: -24px;
        color: #a8b8b3;
        font-size: 14px;
        // 951+ height
        @media (min-height: 951px) and (max-width: 1800px) {
            font-size: 12px;
        }
        @media (min-height: 951px) and (max-width: 1500px) {
            font-size: 10px;
        }
        // 801 - 950 height
        @media (min-height: 801px) and (max-height: 950px) {
            font-size: 12px;
        }
        @media (min-height: 801px) and (max-height: 950px) and (max-width: 1732px) {
            font-size: 10px;
        }
        @media (min-height: 801px) and (max-height: 950px) and (max-width: 1400px) {
            font-size: 8px;
        }
        // 701 - 800 height
        @media (max-height: 800px) {
            font-size: 10px;
        }
        @media (max-height: 800px) and (max-width: 1624px) {
            font-size: 8px;
        }
        @media (max-height: 700px), (max-width: 1300px) {
            font-size: 14px;
        }
    }
}
