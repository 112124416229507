@import '../../App.scss';

// Local Variables

$header-offset: 80px;
$mobile-header-offset: 56px;

.subheader {
    top: $header-offset;
    position: fixed;
    width: 100vw;
    height: 64px;
    background: $dark-green;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 3;
    @media (max-width: 1300px) {
        top: $mobile-header-offset;
    }
    a {
        font-family: $avenir-book;
        color: #b0b8b5;
        text-transform: uppercase;
        font-size: 16px;
        line-height: 64px;
        letter-spacing: 0.71px;
        padding: 0 24px;
        transition: all 0.25s;
        @include cubic;
        &:hover {
            color: $white;
        }
        &.active {
            color: $white;
        }
        @media (max-width: 1800px) {
            font-size: 12px;
        }
    }
    @media (max-width: 1300px) {
        &.area-subheader {
            display: none;
        }
    }
}
