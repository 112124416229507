@import '../../App.scss';

.floorplan-details-fullscreen {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: $white;
    display: none;
    z-index: 4;
    .floorplan-details-floorplans {
        height: calc(100% - 112px);
        padding: 56px 168px;
        display: flex;
        justify-content: center;
    }
    .floorplan-details-floorplans {
        .floorplan-details-floorplan-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            margin-left: 24px;
            height: 100%;
            max-width: 300px;
            &:first-of-type {
                margin-left: 0;
            }
            img {
                max-height: calc(100% - 64px);
                max-width: 100%;
                height: auto;
                display: block;
                width: 100vw;
            }
        }
    }
    .floorplan-details-fullscreen-close {
        background: $magenta;
        color: #fff;
        font-family: $avenir-book;
        text-transform: uppercase;
        padding: 16px 24px;
        position: absolute;
        top: 0;
        right: 0;
        font-size: 13px;
        line-height: calc(18 / 13);
        letter-spacing: 0.87px;
        display: flex;
        align-items: center;
        cursor: pointer;
        box-sizing: border-box;
        transition: all 0.25s;
        @include cubic;
        border: 1px solid $magenta;
        &:hover {
            color: $magenta;
            background: $white;
            border: 1px solid $magenta;
            svg,
            svg * {
                fill: $magenta;
            }
        }
        svg {
            margin-right: 8px;
            transition: all 0.25s;
            @include cubic;
        }
    }
    .floorplan-details-back-container,
    .floorplan-details-next-container {
        font-family: $avenir-medium;
        font-size: 13px;
        line-height: calc(18 / 13);
        letter-spacing: 0.6px;
        color: $dark-text;
        text-transform: uppercase;
        display: flex;
        align-items: center;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        button {
            background: $magenta;
            width: 56px;
            height: 56px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 24px;
            display: inline-block;
            transition: all 0.25s;
            @include cubic;
            &:hover {
                color: $magenta;
                background: $white;
                border: 1px solid $magenta;
                svg,
                svg * {
                    stroke: $magenta;
                }
            }
            svg {
                height: 24px;
                transition: all 0.25s;
                @include cubic;
            }
        }

        &.disabled {
            pointer-events: none;
            button {
                opacity: 0.25;
            }
        }
    }
    .floorplan-details-back-container {
        left: 0;
    }
    .floorplan-details-next-container {
        right: 0;
    }
    .floorplan-details-fullscreen-flowers-top {
        position: absolute;
        top: 0;
        left: 0;
        height: 33.3%;
    }
    .floorplan-details-fullscreen-flowers-bottom {
        position: absolute;
        bottom: 0;
        right: 0;
        height: 33.3%;
    }
    .floorplan-details-floorplan-name {
        font-family: $avenir-heavy;
        text-transform: uppercase;
        color: $dark-text;
        font-size: 16px;
        line-height: 1.5;
        margin-top: 40px;
        text-align: center;
    }
}
