@import '../../App.scss';

// Local Variables

$header-offset: 192px;

.floorplans-list {
    padding-top: $header-offset;
    position: relative;
    @media (max-width: 1300px) {
        padding-top: 168px;
    }
    .top-bar-bg {
        position: absolute;
        @supports (position: sticky) {
            position: fixed;
            top: $header-offset;
        }
        width: 100%;
        height: 64px;
        background: #f5f1eb;
        z-index: 1;
        @media (max-width: 1300px) {
            top: 168px;
        }
    }
    .list-flowers-left {
        position: fixed;
        bottom: 0;
        left: 0;
        width: calc(
            (100% - 1232px - 64px) / 2
        ); // browser width minus grid width minus padding divided by two to account for each side
        @media (max-width: 1600px) {
            display: none;
        }
    }
    .list-flowers-right {
        position: fixed;
        bottom: 0;
        right: 0;
        width: calc(
            (100% - 1200px - 64px) / 2
        ); // browser width minus grid width minus padding divided by two to account for each side
        z-index: -1; // to go below scrollbar
        @media (max-width: 1600px) {
            display: none;
        }
    }
}
