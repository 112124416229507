@import '../../App.scss';

// Local Variables

$header-offset: 144px;
$mobile-header-offset: 56px;

.amenities {
    padding-top: $header-offset;
    height: calc(100% - #{$header-offset});
    width: 100vw;
    background: $white;
    color: $dark-text;
    display: flex;
    flex-shrink: 0;
    @media (max-width: 1300px) {
        height: calc(100% - #{$mobile-header-offset});
        padding-top: $mobile-header-offset;
        flex-direction: column-reverse;
        padding-top: 0;
    }
    .map-container {
        position: relative;
        // background: url('/img-temp/paper-texture.png') no-repeat center center /
        //     cover;
        background: #fffbf5;
        width: 100%;
        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            object-position: center;
        }
        // .overlay {
        //     position: absolute;
        //     width: 100%;
        //     height: 100%;
        //     top: 0;
        //     left: 0;
        //     background: linear-gradient(
        //         90deg,
        //         rgba(255, 255, 255, 1) 0%,
        //         rgba(255, 255, 255, 0) 16%
        //     );
        //     @media (max-width: 1300px) {
        //         display: none;
        //     }
        // }
    }
}
