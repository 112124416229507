@import "../../App.scss";


.compare-floorplans-list-container-select-product {
    font-family: $avenir-book;
    color: $white;
    background: transparent;
    font-size: 13px;
    letter-spacing: 0.87px;
    line-height: calc(18 / 13);
    padding: 0 32px;
    text-transform: uppercase;
    height: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;
    height: 100%;
    display: block;
    
    &.compare-floorplans-list-container-select-product-active {
        color: $dark-text;
        background: #f5f1eb;
    }
}