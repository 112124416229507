@import '../../App.scss';

// Local Variables

$header-offset: 80px;
$mobile-header-offset: 56px;

.product {
    display: flex;
    height: calc((100vh - #{$header-offset} - 24px) / 2);
    margin-bottom: 24px;
    @media (max-width: 1300px), (max-height: 700px) {
        flex-direction: column;
        height: 100%;
        margin-bottom: 0;
    }
    @media (max-width: 768px) {
        height: auto;
    }
    &:last-child {
        margin-bottom: 0;
        .product-info-right {
            @media (max-width: 768px) {
                justify-content: flex-end;
            }
            img {
                @media (max-width: 1300px), (max-height: 700px) {
                    object-position: bottom right;
                }
            }
        }
    }
    .product-image {
        width: 50%;
        background: no-repeat center center / cover;
        @media (max-width: 1300px), (max-height: 700px) {
            width: 100%;
            height: 30%;
            flex-shrink: 0;
        }
        @media (max-width: 768px) {
            height: 200px;
        }
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
        }
    }
    .product-info {
        width: 50%;
        background: $white;
        display: flex;
        @media (max-width: 1300px), (max-height: 700px) {
            width: 100%;
            flex-direction: column;
            flex: 1;
        }
    }
    .product-info-left {
        width: 50%;
        padding: 16px;
        padding-left: 104px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        @media (max-width: 1300px), (max-height: 700px) {
            padding: 40px 24px 0;
            width: 100%;
            box-sizing: border-box;
        }
    }
    .product-info-right {
        width: 50%;
        @media (max-width: 1300px), (max-height: 700px) {
            flex: 1;
            width: 100%;
            position: relative;
        }
        @media (max-width: 768px) {
            display: flex;
            margin-bottom: 56px;
        }
        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            object-position: bottom right;
            @media (max-width: 1300px), (max-height: 700px) {
                position: absolute;
                max-height: 100%;
                object-position: bottom left;
            }
            @media (max-width: 768px) {
                display: none;
            }
        }
    }
    h2 {
        color: $dark-text;
        line-height: 1;
        margin-bottom: 36px;
        @include serif;
        font-size: 40px;
        @media (max-width: 1600px) {
            font-size: 32px;
            margin-bottom: 24px;
        }
        @media (max-height: 950px) {
            font-size: 32px;
            margin-bottom: 24px;
        }
        @media (max-height: 800px) {
            font-size: 24px;
            margin-bottom: 16px;
        }
    }
    p {
        color: $dark-text;
        font-size: 18px;
        line-height: 1.556;
        @media (max-width: 1600px) {
            font-size: 14px;
            margin-bottom: 16px;
        }
        @media (max-height: 950px) {
            font-size: 14px;
            margin-bottom: 16px;
        }
        @media (max-height: 800px) {
            font-size: 12px;
        }
    }
}
