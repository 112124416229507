@import '../../App.scss';

.submit {
    width: calc(50% - 12px);
    @media (max-width: 768px) {
        width: 100%;
    }
    .submit-button {
        width: 100%;
        height: 48px;
        border-radius: 0;
        border: 0;
        text-transform: uppercase;
        outline: none;
        background: $white;
        color: $dark-text;
        font-size: 12px;
        letter-spacing: 0.8px;
        transition: all 0.25s;
        cursor: pointer;
        border: 1px solid $white;
        display: flex;
        justify-content: center;
        align-items: center;
        @include cubic;
        box-sizing: border-box;
        &:hover {
            background: $dark-green;
            color: $white;
        }
    }
}
