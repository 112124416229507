
.mini-site-plan {
    position: relative;

    .mini-site-plan-image {
        max-width: 100%;
        height: auto;
        display: block;
    }

    .site-plan-svg {
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
    }

    .no-click-cover {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}