@import '../../App.scss';

.cover {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    background: $dark-green;
    cursor: default;
    transition: all 0.25s;
    @include cubic;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
        opacity: 0;
        transition: all 1s;
        @include cubic;
        stroke: $white;
    }
}
