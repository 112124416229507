@import '../../App.scss';

.floorplan-details-floorplan-panel {
    border: 1px solid #e0e0e0;
    border-left: none;
    width: 50%;
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
    text-align: center;
    position: relative;
    height: 100%;
    @media (max-width: 1600px) {
        border-top: none;
    }
    &.floorplan-panel-compare {
        .floorplan-details-floorplan-container {
            img {
                height: 100%;
            }
        }
        .grid-floorplan-image-container {
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        @media (max-width: 1600px) {
            .floorplan-details-tab {
                margin: 0 8px;
            }
        }
    }
    .floorplan-details-floorplans {
        //padding: 56px 152px;
        // padding-bottom: 56px;
        display: flex;
        justify-content: center;
        height: 100%;
        position: relative;
    }
    .floorplan-details-floorplan-container {
        margin-left: 120px;
        height: 100%;
        &:first-of-type {
            margin-left: 0;
        }
        img {
            height: calc(100% - 64px);
        }
    }
    .floorplan-details-floorplan-name {
        font-family: $avenir-heavy;
        text-transform: uppercase;
        color: $dark-text;
        font-size: 16px;
        line-height: 1.5;
        margin-top: 40px;
        text-align: center;
    }
    .zoom-container {
        position: absolute;
        bottom: 40px;
        right: 40px;
        width: 56px;
        height: 56px;
        background: $magenta;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        transition: all 0.25s;
        @include cubic;
        border: 1px solid $magenta;
        &:hover {
            color: $magenta;
            background: $white;
            border: 1px solid $magenta;
            .magnifier {
                stroke: $magenta;
            }
            .sign {
                fill: $magenta;
            }
        }
    }
    &.floorplan-panel-site-plan {
        width: 50%;
        border-right: none;
        .grid-floorplan-image-container {
            padding: 80px;
            height: calc(100vh - 290px);
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        img {
            max-width: 100%;
            max-height: 100%;
        }
        @media (max-width: 1600px) {
            width: 100%;
            .grid-floorplan-image-container {
                height: auto;
            }
        }
    }
}
