@import '../../App.scss';

.features-and-finishes-list-button {
    .button {
        background: transparent;
        text-transform: uppercase;
        font-family: $avenir-book;
        color: $dark-text;
        font-size: 12px;
        line-height: 1;
        letter-spacing: 0.87px;
        padding: 16px 32px;
        margin-bottom: 8px;
        width: 100%;
        text-align: left;
        display: flex;
        justify-content: space-between;
        align-items: center;
        line-height: 1.4;
        @media (max-height: 950px) {
            margin-bottom: 0;
        }
        @media (max-height: 850px) {
            padding: 8px 32px;
        }
        @media (max-height: 700px), (max-width: 1300px) {
            padding: 12px 16px;
        }
        @media (max-width: 374px) {
            font-size: 9.5px;
        }
        svg {
            height: 13px;
            position: relative;
            top: -1px;
            margin-left: 24px;
            path {
                stroke: $dark-text;
            }
        }
        &.active {
            background: #e4e6d8;
        }
    }
}
