@import '../../App.scss';

// Local Variables

$header-offset: 144px;
$mobile-header-offset: 120px;

.gallery-videos {
    padding-top: $header-offset;
    height: calc(100vh - #{$header-offset});
    @media (max-width: 1300px) {
        padding-top: $mobile-header-offset;
        // height: calc(100vh - #{$mobile-header-offset});
        height: auto;
        min-height: calc(100vh - #{$mobile-header-offset});
        background: $dark-green;
    }
    .button-container {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate3d(-50%, -50%, 0);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-family: $avenir-heavy;
        font-size: 32px;
        line-height: calc(44 / 32);
        letter-spacing: 1.16px;
        color: $white;
        text-transform: uppercase;
        transition: all 0.25s;
        z-index: 1;
        @include cubic;
        //pointer-events: none;
        width: 320px;
        @media (max-width: 768px) {
            font-size: 16px;
        }
        &.playing {
            display: none;
        }
        // &:hover {
        //     color: $magenta;
        //     svg {
        //         .st0 {
        //             fill-rule: evenodd;
        //             clip-rule: evenodd;
        //             fill: $magenta;
        //         }
        //         .st1 {
        //             fill-rule: evenodd;
        //             clip-rule: evenodd;
        //             fill: $white;
        //         }
        //     }
        // }
    }
    .faux-thumb {
        width: 100%;
        height: 100%;
        object-fit: contain;
        @media (max-width: 1300px) {
            position: absolute;
            top: 0;
            left: 0;
        }
    }
    .play-button-icon {
        width: 80px;
        margin-bottom: 24px;
        @media (max-width: 768px) {
            width: 40px;
            margin-bottom: 16px;
        }
        .st0 {
            fill-rule: evenodd;
            clip-rule: evenodd;
            fill: #ffffff;
            transition: all 0.25s;
            @include cubic;
        }
        .st1 {
            fill-rule: evenodd;
            clip-rule: evenodd;
            fill: #9b3465;
            transition: all 0.25s;
            @include cubic;
        }
    }
    .video-container {
        width: 100%;
        height: 100%;
        overflow: hidden;
        position: relative;
        cursor: pointer;
        background: $lighter-green;
        &:hover {
            .button-container {
                // color: $magenta;
                svg {
                    .st0 {
                        fill-rule: evenodd;
                        clip-rule: evenodd;
                        fill: $magenta;
                    }
                    .st1 {
                        fill-rule: evenodd;
                        clip-rule: evenodd;
                        fill: $white;
                    }
                }
            }
        }
        #video {
            width: 100%;
            height: 100%;
            object-fit: contain;
            position: relative;
            z-index: 1;
            @media (max-width: 1300px) {
                position: relative;
                width: 100%;
                top: 0;
                left: 0;
                transform: none;
                display: block;
            }
        }
    }
}
