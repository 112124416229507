@import '../../App.scss';

.main-header {
    background: $lighter-green;
    height: 80px;
    position: fixed;
    top: 0;
    width: 100%;
    width: 100vw;
    z-index: 4;
    .main-header-logos {
        position: absolute;
        left: 0;
        height: 100%;
        padding-left: 48px;
        display: flex;
        align-items: center;
        @media (max-width: 1800px) {
            padding-left: 24px;
        }
        a {
            margin-right: 32px;
            position: relative;
            &:after {
                content: '';
                position: absolute;
                width: 1px;
                height: 100%;
                right: -16px;
                background: #232e2a;
            }
            img {
                height: 100%;
            }
            @media (max-width: 1800px) {
                height: 24px;
            }
        }
    }
    .main-header-links {
        display: flex;
        justify-content: center;
        height: 100%;
        a {
            color: #c9d4d1;
            font-family: $avenir-book;
            font-size: 12px;
            height: 100%;
            display: block;
            text-transform: uppercase;
            box-sizing: border-box;
            margin: 0 24px;
            transition: all 0.25s;
            @include cubic;
            letter-spacing: 0.87px;
            border-top: 4px solid $lighter-green;
            border-bottom: 4px solid $lighter-green;
            display: flex;
            align-items: center;
            &.main-header-link-active {
                color: #fff;
                border-top: 4px solid #fff;
            }
            &:hover {
                color: #fff;
            }
            @media (max-width: 1800px) {
                margin: 0 12px;
            }
            @media (max-width: 1400px) {
                font-size: 10px;
            }
        }
    }
    .main-header-right-links {
        position: absolute;
        right: 0;
        top: 0;
        height: 100%;
        padding-right: 48px;
        display: flex;
        align-items: center;
        @media (max-width: 1800px) {
            padding-right: 24px;
        }
        button {
            font-family: $avenir-book;
            text-transform: uppercase;
            font-size: 12px;
            letter-spacing: 0.87px;
            color: $white;
            margin-left: 48px;
            background: transparent;
            display: flex;
            align-items: center;
            @media (max-width: 1800px) {
                margin-left: 16px;
            }
            @media (max-width: 1400px) {
                font-size: 10px;
            }
        }
        a {
            font-family: $avenir-book;
            text-transform: uppercase;
            font-size: 12px;
            letter-spacing: 0.87px;
            color: $white;
            margin-left: 48px;
            display: flex;
            align-items: center;
        }
        svg {
            height: 24px;
            margin-right: 8px;
            position: relative;
            top: -2px;
            stroke: $white;
            fill: $white;
            @media (max-width: 1800px) {
                height: 16px;
            }
            // * {
            //     stroke: $white;
            // }
        }
        &.header-register {
            color: $white;
            padding: 0;
            text-transform: uppercase;
            font-family: $avenir-book;
            font-size: 13px;
            letter-spacing: 0.87px;
            a {
                margin-left: 0;
                height: 100%;
                padding: 0 40px;
                background: $magenta;
                border: 1px solid $magenta;
                transition: all 0.25s;
                @include cubic;
                box-sizing: border-box;
                @include hover-supported() {
                    &:hover {
                        background: $white;
                        color: $magenta;
                    }
                }
            }
        }
    }
    .mobile-menu-container {
        overflow: hidden;
        @media (min-width: 1301px) {
            height: 100% !important;
        }
    }
    .mobile-menu-sizer {
        display: flex;
        flex-direction: column;
        overflow: auto;
    }
    .header-register-floorplans {
        display: none;
    }
    .hamburger-container {
        display: none;
    }
    .centerer {
        @media (min-width: 1301px) {
            height: 80px !important;
        }
    }
    @media (max-width: 1300px) {
        height: 56px;
        .main-header-logos {
            a {
                height: 16px;
                margin-right: 0;
                &:after {
                    display: none;
                }
            }
            .main-header-marlin-spring-logo {
                display: none;
            }
        }
        .main-header-right-links.header-register {
            a {
                padding: 0 24px;
            }
        }
        .mobile-menu-container {
            height: auto;
            margin-top: 56px;
            background: $dark-green;
            height: 0;
            transition: all 0.5s;
            @include cubic;
        }
        .centerer {
            margin: auto;
        }
        .main-header-links {
            flex-direction: column;
            align-items: center;
            height: auto;
            padding: 24px 0;
            margin: auto;
            a {
                border: none;
                font-size: 13px;
                padding: 16px;
                &.main-header-link-active {
                    border: none;
                }
            }
        }
        .main-header-star-section {
            display: none;
        }
        .header-register-floorplans {
            display: flex;
        }
        .hamburger-container {
            display: block;
            cursor: pointer;
            background: $dark-green;
            height: 100%;
        }
    }
}
