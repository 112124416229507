@import '../../App.scss';

// Local Variables

$header-offset: 80px;

.form-select {
    select {
        display: none;
    }
    .select {
        background: #3e524b;
        border-bottom: 2px solid #00100a;
        cursor: pointer;
        position: relative;
        flex-direction: column;
    }
    .label {
        padding: 16px 40px;
        text-align: center;
        position: relative;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        transition: all 0.25s;
        outline: none;
        @include cubic;
        &:hover {
            color: #c9d4d1;
        }
        svg {
            transition: all 0.25s;
            @include cubic;
        }
        &.active {
            svg {
                transform: scaleY(-1);
            }
        }
    }
    .options {
        width: 100%;
        box-sizing: border-box;
        transition: all 0.25s;
        overflow: hidden;
        @include cubic;
        position: absolute;
        z-index: 1;
        cursor: default;
        pointer-events: none;
        li {
            background: $white;
            padding: 16px;
            transition: all 0.25s;
            @include cubic;
            color: $dark-green;
            text-align: center;
            cursor: pointer;
            pointer-events: auto;
            &:hover {
                color: #c9d4d1;
                background: #3e524b;
            }
        }
    }
    .down-arrow {
        position: absolute;
        right: 16px;
    }
    &.two-column {
        width: calc(50% - 12px);
        @media (max-width: 768px) {
            width: 100%;
            &:first-of-type {
                margin-bottom: 24px;
            }
        }
        .options {
            z-index: 2;
        }
        &.top {
            .options {
                z-index: 3;
            }
        }
    }
}
