@import '../../App.scss';

.features-and-finishes-list {
    height: 100%;
    padding: 40px 48px 40px 32px;
    display: flex;
    box-sizing: border-box;
    overflow: hidden;
    width: 100vw;
    flex-shrink: 0;
    background: #f4f5ec;
    @media (max-height: 700px), (max-width: 1300px) {
        flex-direction: column;
        width: 100%;
        padding-left: 16px;
        padding-right: 16px;
    }
    @media (max-width: 1300px) {
        padding: 0 0 40px 0;
    }
    .list-buttons {
        display: flex;
        flex-direction: column;
        flex-shrink: 0;
        justify-content: space-between;
        @media (max-width: 1300px) {
            display: none;
        }
    }
    .list {
        column-count: 3;
        column-gap: 60px;
        color: $dark-text;
        font-size: 14px;
        line-height: 1.5;
        @media (max-width: 1600px) {
            column-count: 2;
        }
        // Sorry
        // 951+ height
        @media (min-height: 951px) and (max-width: 1800px) {
            font-size: 12px;
        }
        @media (min-height: 951px) and (max-width: 1500px) {
            font-size: 10px;
        }
        // 801 - 950 height
        @media (min-height: 801px) and (max-height: 950px) {
            font-size: 12px;
        }
        @media (min-height: 801px) and (max-height: 950px) and (max-width: 1732px) {
            font-size: 10px;
        }
        @media (min-height: 801px) and (max-height: 950px) and (max-width: 1400px) {
            font-size: 8px;
        }
        // 701 - 800 height
        @media (max-height: 800px) {
            font-size: 10px;
        }
        @media (max-height: 800px) and (max-width: 1624px) {
            font-size: 8px;
        }
        @media (max-height: 700px), (max-width: 1300px) {
            font-size: 14px;
        }
        @media (max-width: 768px) {
            column-count: 1;
        }
    }
    .list-items {
        flex-grow: 1;
        padding-left: 80px;
        @media (max-height: 700px), (max-width: 1300px) {
            padding-top: 40px;
            padding-left: 16px;
            padding-right: 16px;
        }
        @media (max-width: 1300px) {
            padding-left: 32px;
        }
        @media (max-width: 768px) {
            padding-top: 0;
        }
        .main-button {
            @media (max-width: 768px) {
                margin-left: -32px;
                margin-right: -16px;
                padding-top: 24px !important;
                padding-bottom: 24px !important;
            }
        }
    }
    .list-items-header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 40px;
        @media (max-height: 950px) {
            margin-bottom: 24px;
        }
        @media (max-height: 700px), (max-width: 1300px) {
            margin-bottom: 40px;
        }
        @media (max-width: 768px) {
            flex-direction: column-reverse;
        }
    }
    .list-items-title {
        @include serif;
        color: $dark-text;
        font-size: 40px;
        line-height: calc(48 / 40);
        margin-right: 24px;
        @media (max-width: 768px) {
            margin-top: 48px;
            font-size: 32px;
        }
    }
    .mobile-list-select {
        border: none;
        background: #e4e6d8;
        font-size: 13px;
        letter-spacing: 0.87px;
        text-transform: uppercase;
        padding: 24px;
        padding-right: 32px;
        color: #003322;
        outline: none;
        display: none;
        @media (max-width: 1300px) {
            display: block;
        }
    }
}
