@import '../../App.scss';

.gallery-slider {
    // .swiper-container {
    //     padding-left: 48px;
    // }
    .swiper-wrapper {
        @media (max-width: 1300px) {
            flex-direction: column;
            margin-bottom: 40px;
            margin-top: 40px;
        }
    }
    .swiper-slide {
        width: auto;
        @media (max-width: 1300px) {
            // width: calc(100% - 40px);
            margin-bottom: 40px;
        }
        img {
            height: calc(100vh - 360px);
            display: block;
            @media (max-width: 1300px) {
                height: auto;
                width: calc(100% - 48px);
                margin: 0 24px;
            }
        }
    }
    .main-swiper {
        margin: 40px 0;
        .swiper-slide {
            display: flex;
            width: 100%;
            justify-content: center;
            // &:last-of-type {
            //     width: 100%;
            //     @media (max-width: 1300px) {
            //         width: calc(100% - 40px);
            //     }
            // }
        }
    }
    .thumb-swiper-container {
        display: flex;
        justify-content: center;
    }
    .thumb-swiper {
        margin-bottom: 24px;
        // margin-left: 160px;
        justify-content: center;
        display: inline-flex;
        @media (max-width: 1300px) {
            display: none;
        }
        .swiper-slide {
            height: 56px;
            filter: grayscale(100%);
            transition: filter 0.25s;
            @include cubic;
            cursor: pointer;
            img {
                height: 100%;
            }
        }
        .swiper-slide-thumb-active {
            filter: none;
        }
    }
    .caption {
        font-family: $avenir-heavy;
        color: $dark-text;
        text-align: center;
        font-size: 14px;
        line-height: calc(24 / 14);
        margin: 16px 0 16px;
        text-transform: uppercase;
    }
    .buttons {
        position: absolute;
        bottom: 24px;
        left: 48px;
        @media (max-width: 1300px) {
            display: none;
        }
    }
    button {
        background: $magenta;
        width: 56px;
        height: 56px;
        display: flex;
        align-items: center;
        justify-content: center;
        display: inline-block;
        transition: all 0.25s;
        @include cubic;
        border: 1px solid $magenta;
        &:hover {
            color: $magenta;
            background: $white;
            border: 1px solid $magenta;
            svg,
            svg * {
                stroke: $magenta;
            }
        }
        svg {
            height: 24px;
            transition: all 0.25s;
            @include cubic;
        }
    }
    .next-button {
        margin-left: 8px;
    }
    .swiper-button-disabled {
        opacity: 0.25;
        &:hover {
            color: $white;
            background: $magenta;
            border: 1px solid $magenta;
            svg,
            svg * {
                stroke: $white;
            }
        }
    }
}
