@import '../../App.scss';

.features-and-finishes-hero {
    height: 100%;
    flex-shrink: 0;
    display: flex;
    width: 100vw;
    @media (max-width: 1300px), (max-height: 700px) {
        flex-direction: column;
        width: 100vw;
        height: auto;
    }
    .copy {
        background: $dark-green;
        padding: 104px 56px 0;
        width: 528px;
        box-sizing: border-box;
        height: 100%;
        color: $white;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        flex-shrink: 0;
        @media (max-height: 800px) {
            padding-top: 64px;
        }
        @media (max-width: 1300px), (max-height: 700px) {
            width: 100%;
            height: auto;
            padding-bottom: 80px;
        }
        @media (max-width: 768px) {
            padding-left: 32px;
            padding-right: 32px;
            .main-button {
                font-size: 10px;
                padding-left: 16px;
                padding-right: 16px;
                width: 100%;
                svg {
                    margin-right: 16px;
                }
            }
        }
    }
    h1 {
        @include serif;
        font-size: 64px;
        line-height: 1;
        margin-bottom: 56px;
        @media (max-height: 950px) {
            font-size: 56px;
            margin-bottom: 40px;
        }
        @media (max-width: 768px) {
            font-size: 32px;
        }
    }
    h2 {
        font-family: $avenir-medium;
        font-size: 22px;
        line-height: calc(32 / 22);
        margin-bottom: 24px;
        width: 320px;
        @media (max-width: 1300px) {
            width: auto;
        }
        @media (max-height: 950px) {
            font-size: 18px;
        }
        @media (max-width: 1300px), (max-height: 700px) {
            max-width: 600px;
        }
    }
    p {
        font-family: $avenir-book;
        font-size: 18px;
        line-height: calc(28 / 18);
        margin-bottom: 16px;
        width: 320px;
        @media (max-width: 1300px) {
            width: auto;
        }
        @media (max-height: 950px) {
            font-size: 14px;
        }
        @media (max-width: 1300px), (max-height: 700px) {
            max-width: 600px;
        }
        &:last-of-type {
            margin-bottom: 64px;
        }
    }
    .hero-image-container {
        height: 100%;
        width: calc(100vw - 528px);
        display: flex;
        align-items: center;
        justify-content: center;
        background: $dark-green;
        @media (max-width: 1300px) {
            width: 100%;
        }
    }
    .features-hero-image {
        max-height: 100%;
        max-width: 100%;
        @media (max-width: 1300px), (max-height: 700px) {
            height: auto;
            width: 100vw;
        }
    }
}
