@import '../../App.scss';

.form-radio {
    display: flex;
    border: 1px solid $white;
    display: flex;
    background: $dark-green;
    position: relative;
    z-index: 1;
    box-sizing: border-box;
    @media (max-width: 768px) {
        margin-bottom: 24px;
        border: none;
        flex-direction: column;
    }
    .form-radio-single {
        display: flex;
        align-items: center;
        padding: 0 16px;
        cursor: pointer;
        transition: all 0.25s;
        @include cubic;
        @media (max-width: 768px) {
            padding: 8px 0;
        }
        input {
            display: none;
        }
        .radio-icon {
            margin-right: 8px;
            @media (max-width: 1600px) {
                margin-top: -4px;
            }
        }
        .radio-fill {
            opacity: 0;
            transition: all 0.25s;
            @include cubic;
            fill: $white;
        }
        &:hover {
            color: #c9d4d1;
        }
        &.active {
            color: $white;
            .radio-fill {
                opacity: 1;
            }
        }
        label {
            cursor: pointer;
        }
    }
    .radio-label {
        padding: 16px;
        border-right: 1px solid $white;
        width: 184px;
        @media (max-width: 768px) {
            border: none;
            padding-left: 0;
            padding-bottom: 8px;
            text-align: left;
        }
    }
    .radio-buttons-list {
        display: flex;
        justify-content: space-around;
        flex: 1;
        @media (max-width: 768px) {
            flex-direction: column;
        }
    }
}
