@import "../../App.scss";

.radio-filter {
    display: flex;
    align-items: center;
    height: 32px;
    
    svg {
        fill: $white;
        height: 16px;
        width: 24px;
        margin-right: 24px;
        position: relative;
        top: -1px;
        &.block-icon {
            fill-opacity: 0;
        }
        &.down-arrow {
            margin-right: 0;
            margin-left: 24px;
            width: 12px;
        }
    }

    button {
        padding: 0;
    }
}