.virtual-keyboard {
    position: fixed;
    top: 100%;
    left: 50%;
    width: 100%;
    max-width: 1000px;
    transform: translate(-50%, 0%);
    z-index: 10000;
    transition: transform 0.2s ease-in-out;

    &.open {
        transform: translate(-50%, -100%);
    }
}