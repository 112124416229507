@import '../../App.scss';

.list-grid-header {
    position: fixed;
    background: $white;
    height: 48px;
    width: 100%;
    top: 144px;
    z-index: 1;
    display: flex;
    justify-content: center;
    z-index: 3;
    font-family: $avenir-book;
    color: $dark-text;
    font-size: 16px;
    line-height: 48px;
    @media (max-width: 1300px) {
        top: 120px;
    }
    .list-grid-header-links {
        position: absolute;
        right: 0;
        display: flex;
        @media (max-width: 768px) {
            display: none;
        }
    }
    .list-grid-link {
        line-height: 48px;
        height: 48px;
        margin-right: 48px;
        font-family: $avenir-medium;
        text-transform: uppercase;
        color: #7a7367;
        font-size: 13px;
        letter-spacing: 0.87px;
        transition: all 0.25s;
        @include cubic;
        display: flex;
        align-items: center;
        svg {
            height: 16px;
            width: 24px;
            margin-right: 8px;
            transition: all 0.25s;
            @include cubic;
            &.list-icon {
                fill: #7a7367;
                * {
                    fill: #7a7367;
                }
            }
            &.grid-icon {
                stroke: #7a7367;
                * {
                    stroke: #7a7367;
                }
            }
        }
        &:hover {
            color: $dark-text;
            svg {
                &.list-icon {
                    fill: $dark-text;
                    * {
                        fill: $dark-text;
                    }
                }
                &.grid-icon {
                    stroke: $dark-text;
                    * {
                        stroke: $dark-text;
                    }
                }
            }
        }
        &.active-list-grid-header {
            color: $dark-text;
            border-bottom: 1px solid $dark-text;
            svg {
                &.list-icon {
                    fill: $dark-text;
                    * {
                        fill: $dark-text;
                    }
                }
                &.grid-icon {
                    stroke: $dark-text;
                    * {
                        stroke: $dark-text;
                    }
                }
            }
        }
    }
    &.list-grid-header-border {
        border-bottom: 1px solid #e0e0e0;
    }
}
