@import '../../App.scss';

// Local Variables

$header-offset: 80px;
$mobile-header-offset: 56px;

.select-products {
    padding-top: $header-offset;
    background: $dark-green;
    font-family: $avenir-book;
    @media (max-width: 1300px) {
        padding-top: $mobile-header-offset;
    }
    @media (max-width: 1300px), (max-height: 700px) {
        display: flex;
        height: calc(100vh - #{$mobile-header-offset});
    }
    @media (max-width: 768px) {
        flex-direction: column;
    }
}
