@import '../../App.scss';

// Local Variables

$header-offset: 144px;
$mobile-header-offset: 56px;
$top-bottom-padding: 56px;

.area-viewport {
    height: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    cursor: grab;
    background: $lighter-green;
    @media (max-width: 1300px) {
        cursor: default;
        height: auto;
    }
}

.area {
    height: 100%;
    display: inline-flex;
    font-family: $avenir-book;
    color: $white;
    overflow-y: hidden;
    .drag-info {
        @media (max-width: 1300px) {
            display: none;
        }
    }
    @media (max-width: 1300px) {
        height: auto;
        flex-direction: column;
        overflow-x: hidden;
        width: 100%;
    }
    #image-load-target {
        display: flex;
        @media (max-width: 1300px) {
            flex-direction: column;
        }
    }
    .area-section-1 {
        padding-left: 160px;
        width: 576px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: calc(100% - #{$header-offset});
        padding-right: 200px;
        position: relative;
        overflow-y: hidden;
        flex-shrink: 0;
        padding-top: $header-offset;
        @media (max-width: 1300px) {
            height: calc(100% - #{$mobile-header-offset});
            padding-top: $mobile-header-offset;
            width: 100%;
            box-sizing: border-box;
            padding-left: 40px;
            padding-right: 40px;
        }
        @media (max-height: 950px) {
            width: 384px;
            justify-content: space-around;
            padding-top: 80px;
            padding-bottom: 56px;
            height: calc(100% - 112px);
        }
        @media (max-height: 950px) and (max-width: 1300px) {
            width: 100%;
            padding-bottom: 0;
        }
        .flex-container {
            display: flex;
            flex-direction: column;
        }
        h1 {
            @include serif;
            font-size: 104px;
            line-height: calc(108 / 104);
            transform: translateY(-16px);
            @media (max-width: 768px) {
                font-size: 32px;
                margin-top: 32px;
                transform: none;
            }
            @media (max-height: 950px) {
                font-size: 72px;
            }
            @media (max-height: 950px) and (max-width: 1300px) {
                font-size: 40px;
            }
        }
        .area-image-1 {
            height: 144px;
            transform: translateY($top-bottom-padding);
            align-self: flex-end;
            @media (max-height: 950px) {
                height: 96px;
            }
            @media (max-width: 768px) {
                height: 96px;
                transform: none;
                padding-top: $top-bottom-padding;
                display: none;
            }
        }
        .area-image-2 {
            width: 100%;
            margin-bottom: $top-bottom-padding;
            @media (max-width: 768px) {
                margin-top: $top-bottom-padding;
                margin-bottom: 0;
                margin-top: 24px;
            }
        }
        .area-flowers {
            position: absolute;
            bottom: 0;
            right: 0;
            width: 320px;
            z-index: 1;
            @media (max-width: 1300px) {
                display: none;
            }
            @media (max-height: 950px) {
                width: 224px;
                right: 56px;
            }
        }
    }
    .area-section-2 {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-end;
        width: 464px;
        padding-bottom: $top-bottom-padding;
        transform: translateX(-72px);
        flex-shrink: 0;
        padding-top: $header-offset;
        @media (max-width: 1300px) {
            padding-top: $mobile-header-offset;
            width: 100%;
            box-sizing: border-box;
            padding-left: 40px;
            padding-right: 40px;
            transform: none;
        }
        @media (max-width: 768px) {
            padding-top: 24px;
            padding-bottom: 16px;
        }
        @media (max-height: 950px) {
            width: 320px;
            transform: none;
            justify-content: space-around;
        }
        @media (max-height: 950px) and (max-width: 1300px) {
            width: 100%;
        }
        .area-image-3 {
            width: 100%;
            margin-bottom: 32px;
            @media (max-height: 950px) {
                margin-top: $top-bottom-padding;
                margin-bottom: 16px;
                width: 264px;
            }
            @media (max-height: 950px) and (max-width: 1300px) {
                width: 100%;
            }
            @media (max-width: 768px) {
                margin-bottom: 16px;
            }
        }
        .area-image-4 {
            width: 216px;
            transform: translateX(160px);
            @media (max-width: 1300px) {
                transform: none;
                width: 300px;
            }
            @media (max-height: 950px) {
                width: 160px;
                transform: translateX(96px);
            }
            @media (max-height: 800px) {
                width: 124px;
                transform: translateX(80px);
            }
            @media (max-width: 768px) {
                width: 100%;
            }
            @media (max-height: 950px) and (max-width: 1300px) {
                transform: none;
                margin-top: 0;
            }
        }
        p {
            width: 360px;
            font-size: 18px;
            line-height: calc(26 / 16);
            margin-bottom: 32px;
            @media (max-width: 1300px) {
                width: 100%;
            }
            @media (max-height: 950px) {
                font-size: 16px;
                width: 320px;
                margin-bottom: 16px;
            }
            @media (max-height: 950px) and (max-width: 1300px) {
                width: 100%;
                margin-top: 16px;
            }
            @media (max-width: 768px) {
                order: -1;
            }
        }
    }
    .area-section-3 {
        padding: calc(#{$top-bottom-padding + $header-offset}) 72px
            $top-bottom-padding 320px;
        height: calc(100% - #{$header-offset});
        width: 976px;
        flex-shrink: 0;
        @media (max-width: 1300px) {
            padding-top: calc(#{$top-bottom-padding + $mobile-header-offset});
            height: calc(100% - #{$mobile-header-offset});
            padding-left: 40px;
            width: 100%;
            box-sizing: border-box;
            padding-right: 40px;
        }
        @media (max-height: 950px) {
            padding-left: 240px;
            width: 840px;
        }
        @media (max-height: 800px) {
            width: 720px;
        }
        @media (max-width: 768px) {
            padding-top: 0;
        }
        @media (max-height: 950px) and (max-width: 1300px) {
            padding-left: 40px;
            width: 100%;
        }
        .area-image-5 {
            width: 100%;
            height: calc(100% - (2 * #{$top-bottom-padding}));
            object-fit: cover;
        }
    }
    .area-section-4 {
        background: #f9faf1;
        position: relative;
        flex-shrink: 0;
        display: flex;
        height: calc(100% - #{$header-offset});
        padding-top: $header-offset;
        @media (max-width: 1300px) {
            padding-top: $mobile-header-offset;
            height: calc(100% - #{$mobile-header-offset});
            flex-direction: column;
        }
        .area-section-4-flowers {
            mix-blend-mode: multiply;
            position: absolute;
            bottom: 0;
            left: 0;
            width: 200px;
            @media (max-width: 1300px) {
                display: none;
            }
        }
        .compass-section {
            padding: 72px 112px 72px 264px;
            width: 440px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            height: calc(100% - 144px);
            @media (max-width: 1300px) {
                padding-left: 40px;
                padding-right: 40px;
                box-sizing: border-box;
                width: 100%;
            }
            @media (max-height: 950px) {
                padding: 56px 80px 56px 264px;
                justify-content: space-around;
                height: calc(100% - 112px);
            }
            @media (max-height: 950px) and (max-width: 1300px) {
                padding-left: 80px;
            }
            @media (max-width: 768px) {
                padding-top: 24px;
                padding-left: 16px;
                padding-right: 16px;
            }
            h2 {
                @include serif;
                color: $dark-text;
                font-size: 72px;
                line-height: 1;
                text-align: center;
                @media (max-height: 950px) {
                    font-size: 48px;
                }
                @media (max-width: 768px) {
                    font-size: 40px;
                }
            }
            p {
                color: $dark-text;
                font-size: 18px;
                line-height: calc(26 / 18);
                text-align: center;
                margin-bottom: 0;
                @media (max-height: 950px) {
                    font-size: 12px;
                    width: 80%;
                }
                @media (max-width: 768px) {
                    font-size: 16px;
                }
            }
        }
        .compass-container {
            width: 288px;
            position: relative;
            @media (max-width: 1300px) {
                margin: 80px 0;
            }
            @media (max-height: 950px) {
                width: 240px;
            }
            @media (max-height: 800px) {
                width: 160px;
            }
            @media (max-width: 768px) {
                width: 240px;
                margin: 40px 0;
            }
            img {
                width: 100%;
            }
            .big-compass {
                position: absolute;
                top: 0;
                left: 0;
                animation: compass 9.6s infinite ease;
                transform: rotate(32deg);
            }
        }
        .map-illustration-container {
            width: calc(100vh - #{$header-offset});
            @media (max-width: 1300px) {
                width: 100%;
            }
            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }
        .area-image-6-container {
            padding: $top-bottom-padding 72px $top-bottom-padding 144px;
            height: 100%;
            width: 976px;
            flex-shrink: 0;
            @media (max-width: 1300px) {
                padding-left: 40px;
                padding-right: 40px;
                box-sizing: border-box;
                width: 100%;
            }
            @media (max-height: 950px) {
                padding-left: 72px;
                width: 840px;
            }
            @media (max-height: 800px) {
                width: 720px;
            }
            @media (max-height: 950px) and (max-width: 1300px) {
                padding-left: 40px;
                padding-right: 40px;
                box-sizing: border-box;
                width: 100%;
            }
            @media (max-width: 768px) {
                padding-bottom: 0;
            }
            .area-image-6 {
                width: 100%;
                height: calc(100% - (2 * #{$top-bottom-padding}));
                object-fit: cover;
            }
        }
    }
    .area-section-5 {
        background: #f9faf1;
        padding: #{$top-bottom-padding + $header-offset} 72px $top-bottom-padding
            0;
        color: $dark-text;
        width: 944px;
        flex-shrink: 0;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        @media (max-width: 1300px) {
            padding-top: $mobile-header-offset;
            width: 100%;
            padding-left: 40px;
            padding-right: 40px;
            box-sizing: border-box;
        }
        @media (max-height: 950px) {
            width: 600px;
            padding-right: 120px;
            justify-content: center;
        }
        @media (max-height: 950px) and (max-width: 1300px) {
            padding-right: 40px;
            width: 100%;
        }
        @media (max-width: 768px) and (max-height: 950px) {
            padding-top: 16px;
            padding-bottom: 24px;
        }
        @media (max-width: 768px) and (min-height: 951px) {
            padding-top: 16px;
            padding-bottom: 40px;
        }
        .top {
            display: flex;
            justify-content: flex-end;
            margin-bottom: 48px;
            @media (max-width: 1024px) {
                margin-bottom: 0;
            }
        }
        .area-image-7 {
            height: 256px;
            @media (max-height: 950px) {
                height: 160px;
            }
            @media (max-width: 768px) {
                width: 100%;
                height: auto;
            }
        }
        .bottom {
            display: flex;
            @media (max-width: 1024px) {
                flex-direction: column-reverse;
            }
            h3 {
                @include serif;
                font-size: 128px;
                line-height: 1;
                margin-bottom: 52px;
                @media (max-height: 950px) {
                    font-size: 80px;
                    margin-bottom: 24px;
                }
                @media (max-width: 768px) {
                    font-size: 40px;
                    margin-top: 40px;
                }
                span {
                    display: block;
                    transform: translateX(-200px);
                    @media (max-width: 1024px) {
                        transform: none;
                    }
                    @media (max-height: 950px) {
                        transform: translateX(-120px);
                    }
                    @media (max-height: 950px) and (max-width: 1300px) {
                        transform: none;
                    }
                    @media (max-width: 768px) {
                        display: inline;
                    }
                }
            }
            p {
                width: 376px;
                font-size: 18px;
                line-height: calc(26 / 18);
                @media (max-width: 1024px) {
                    width: auto;
                    padding-right: 24px;
                }
                @media (max-height: 950px) {
                    font-size: 14px;
                    width: 256px;
                }
                @media (max-width: 768px) {
                    font-size: 16px;
                    width: 100%;
                }
            }
        }
        .area-image-8 {
            height: 256px;
            margin-right: 64px;
            @media (max-width: 1024px) {
                margin-top: 24px;
                margin-right: 0;
                width: 100%;
                height: auto;
            }
            @media (max-height: 950px) {
                height: 160px;
                margin-right: 32px;
                transform: translateY(-16px);
            }
            @media (max-height: 950px) and (max-width: 1300px) {
                height: auto;
            }
            @media (max-width: 768px) {
                padding-bottom: 16px;
            }
        }
        .copy-container {
            margin-top: -160px;
            @media (max-width: 1024px) {
                margin-top: 16px;
            }
            @media (max-height: 950px) {
                margin-top: -120px;
            }
            @media (max-height: 950px) and (max-width: 1300px) {
                margin-top: 16px;
            }
        }
        .area-section-5-flowers {
            position: absolute;
            bottom: 0;
            right: 0;
            width: 200px;
            mix-blend-mode: multiply;
            @media (max-width: 1300px) {
                display: none;
            }
        }
    }
    .area-section-6 {
        background: #f9faf1;
        padding-top: $header-offset;
        @media (max-width: 1300px) {
            padding-top: $mobile-header-offset;
        }
        @media (max-width: 768px) {
            padding-top: 0;
        }
        .area-image-9-container {
            padding: $top-bottom-padding 72px;
            height: 100%;
            width: 976px;
            flex-shrink: 0;
            @media (max-width: 1300px) {
                box-sizing: border-box;
                width: 100%;
                padding-left: 40px;
                padding-right: 40px;
            }
            @media (max-height: 950px) {
                width: 840px;
            }
            @media (max-height: 800px) {
                width: 720px;
            }
            @media (max-height: 950px) and (max-width: 1300px) {
                width: 100%;
            }
            @media (max-width: 768px) {
                padding-bottom: 16px;
            }
            .area-image-9 {
                width: 100%;
                height: calc(100% - (2 * #{$top-bottom-padding}));
                object-fit: cover;
            }
        }
    }
    .area-section-7 {
        background: #f9faf1;
        display: flex;
        flex-direction: column;
        height: 100%;
        box-sizing: border-box;
        padding: #{$top-bottom-padding + $header-offset} 72px $top-bottom-padding
            0;
        width: 840px;
        flex-shrink: 0;
        justify-content: space-between;
        @media (max-width: 1300px) {
            padding-top: #{$top-bottom-padding + $mobile-header-offset};
            height: calc(100% - #{$mobile-header-offset});
            box-sizing: border-box;
            width: 100%;
            padding-left: 40px;
            padding-right: 40px;
        }
        @media (max-height: 950px) {
            width: 680px;
            justify-content: center;
        }
        @media (max-width: 768px) {
            padding-top: 0;
            flex-direction: column-reverse;
        }
        @media (max-height: 950px) and (max-width: 1300px) {
            width: 100%;
        }
        @media (max-width: 768px) {
            padding-bottom: 0;
        }
        .top {
            padding-left: 272px;
            @media (max-width: 768px) {
                padding-left: 0;
            }
            .image-container {
                position: relative;
            }
            .area-image-10 {
                width: 344px;
                margin-bottom: 24px;
                position: relative;
                z-index: 1;
                @media (max-height: 950px) {
                    width: 200px;
                    margin-bottom: 16px;
                }
                @media (max-width: 768px) {
                    width: 100%;
                }
            }
            .bird {
                position: absolute;
                top: 100px;
                right: 95%;
                height: 240px;
                mix-blend-mode: multiply;
                @media (max-height: 950px) {
                    top: 74px;
                    height: 120px;
                }
                @media (max-width: 768px) {
                    display: none;
                }
            }
            p {
                width: 344px;
                color: $dark-text;
                font-size: 18px;
                line-height: calc(26 / 18);
                margin-bottom: 0;
                @media (max-height: 950px) {
                    font-size: 14px;
                }
                @media (max-width: 768px) {
                    width: 100%;
                    font-size: 16px;
                    margin-top: 16px;
                }
            }
        }
        .bottom {
            @include serif;
            font-size: 128px;
            line-height: 1;
            color: $dark-text;
            @media (max-height: 950px) {
                font-size: 80px;
                display: flex;
                justify-content: center;
                margin-top: 40px;
                h3 {
                    width: 480px;
                }
            }
            h3 {
                @media (max-width: 768px) {
                    width: 100%;
                    font-size: 40px;
                    margin-bottom: 24px;
                    .second {
                        padding-left: 0;
                    }
                }
            }
        }
        .first {
            display: block;
            @media (max-width: 768px) {
                display: inline;
            }
        }
        .second {
            display: block;
            text-align: right;
            @media (max-width: 1300px) {
                text-align: left;
                padding-left: 320px;
            }
        }
    }
    .area-section-8 {
        background: #f9faf1;
        padding-top: $header-offset;
        @media (max-width: 1300px) {
            padding-top: $mobile-header-offset;
        }
        @media (max-width: 768px) {
            padding-top: 0;
        }
        .area-image-11-container {
            padding: $top-bottom-padding 72px $top-bottom-padding 24px;
            height: 100%;
            width: 976px;
            flex-shrink: 0;
            @media (max-width: 1300px) {
                box-sizing: border-box;
                width: 100%;
                padding-left: 40px;
                padding-right: 40px;
            }
            @media (max-height: 950px) {
                width: 840px;
            }
            @media (max-height: 800px) {
                width: 720px;
            }
            @media (max-height: 950px) and (max-width: 1300px) {
                width: 100%;
            }
            .area-image-11 {
                width: 100%;
                height: calc(100% - (2 * #{$top-bottom-padding}));
                object-fit: cover;
            }
        }
    }
    .area-section-9 {
        background: #f9faf1;
        flex-shrink: 0;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        color: $dark-text;
        padding: #{$top-bottom-padding + $header-offset} 240px $top-bottom-padding
            0;
        width: 750px;
        position: relative;
        overflow: hidden;
        @media (max-width: 1300px) {
            padding-top: #{$top-bottom-padding + $mobile-header-offset};
            box-sizing: border-box;
            width: 100%;
            padding-left: 40px;
        }
        @media (max-height: 950px) {
            width: 480px;
            justify-content: center;
        }
        @media (max-width: 768px) {
            padding-top: 40px;
            padding-right: 40px;
            flex-direction: column-reverse;
        }
        @media (max-height: 950px) and (max-width: 1300px) {
            width: 100%;
        }
        .top {
            padding-left: 312px;
            transform: translateY(136px);
            @media (max-height: 950px) {
                padding-left: 200px;
                transform: translateY(48px);
            }
            @media (max-width: 768px) {
                padding-left: 0;
                transform: none;
            }
            p {
                width: 344px;
                font-size: 18px;
                line-height: calc(26 / 18);
                @media (max-height: 950px) {
                    font-size: 14px;
                }
                @media (max-width: 768px) {
                    width: 100%;
                    font-size: 16px;
                    margin-top: 16px;
                }
            }
        }
        .bottom {
            @media (max-height: 950px) {
                transform: translateY(-48px);
            }
            @media (max-height: 950px) and (max-width: 1300px) {
                transform: none;
            }
            h3 {
                @media (max-width: 1300px) {
                    font-size: 40px;
                    margin-bottom: 24px;
                    .first,
                    .second,
                    .third {
                        text-align: left;
                        @media (max-width: 768px) {
                            display: inline;
                        }
                    }
                    .second {
                        padding-left: 40px;
                    }
                    .third {
                        padding-left: 192px;
                    }
                }
                @media (max-width: 768px) {
                    .second {
                        padding-left: 0;
                    }
                    .third {
                        padding-left: 0;
                    }
                }
            }
        }
        .area-image-12 {
            width: 344px;
            margin-bottom: 24px;
            @media (max-height: 950px) {
                width: 232px;
                margin-bottom: 16px;
            }
            @media (max-width: 768px) {
                width: 100%;
            }
        }
        .flowers {
            position: absolute;
            bottom: 0;
            right: -224px;
            width: 400px;
            mix-blend-mode: multiply;
            @media (max-width: 1024px) {
                display: none;
            }
            @media (max-height: 950px) {
                width: 264px;
                right: -52px;
            }
        }
        h3 {
            @include serif;
            font-size: 128px;
            line-height: 1;
            @media (max-height: 950px) {
                font-size: 80px;
            }
            span {
                display: block;
            }
            .second {
                text-align: center;
            }
            .third {
                text-align: right;
            }
        }
    }
    .area-section-10 {
        background: #d8ecf0;
        flex-shrink: 0;
        padding: #{$top-bottom-padding + $header-offset} 56px 0 56px;
        color: $dark-text;
        position: relative;
        display: flex;
        height: calc(100% - #{$header-offset});
        @media (max-width: 1300px) {
            padding-top: #{$top-bottom-padding + $mobile-header-offset};
            height: calc(100% - #{$mobile-header-offset});
            flex-direction: column;
        }
        // @media (max-height: 950px) {
        //     padding-top: 160px;
        // }
        // @media (max-height: 800px) {
        //     padding-top: $top-bottom-padding;
        // }
        @media (max-width: 768px) {
            padding-top: 80px;
            padding-left: 40px;
            padding-right: 40px;
        }
        .copy {
            width: 600px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            height: calc(100% - #{$top-bottom-padding});
            max-width: 100%;
            // @media (max-height: 950px) {
            //     height: calc(100% - 160px);
            // }
            @media (max-height: 800px) {
                height: calc(100% - #{$top-bottom-padding});
            }
            h4 {
                @include serif;
                font-size: 64px;
                line-height: calc(72 / 64);
                margin-bottom: 40px;
                @media (max-height: 950px) {
                    font-size: 32px;
                    margin-bottom: 24px;
                }
                @media (max-width: 768px) {
                    font-size: 40px;
                }
            }
            p {
                width: 440px;
                font-size: 18px;
                line-height: calc(26 / 18);
                @media (max-height: 950px) {
                    font-size: 14px;
                }
                @media (max-width: 768px) {
                    width: 100%;
                    font-size: 16px;
                }
            }
            .image-container {
                flex: 1;
                position: relative;
                @media (max-width: 1300px) {
                    display: none;
                }
            }
            img {
                position: absolute;
                bottom: 0;
                left: 0;
                height: 96%;
            }
        }
        .area-gallery {
            padding-top: 192px;
            padding-bottom: 56px;
            display: flex;
            margin-left: -80px;
            align-self: flex-start;
            @media (max-width: 1300px) {
                margin-left: 0;
                flex-direction: column;
                padding-top: 64px;
            }
            @media (max-height: 950px) {
                padding-top: 64px;
            }
            @media (max-width: 768px) {
                padding-top: 80px;
                width: 100%;
            }
            .container {
                margin-right: 48px;
                @media (max-width: 1300px) {
                    margin-right: 0;
                }
            }
            img {
                height: 400px;
                margin-bottom: 16px;
                @media (max-width: 1300px) {
                    height: auto;
                    width: 100%;
                }
                @media (max-height: 950px) {
                    height: 264px;
                }
                @media (max-height: 950px) and (max-width: 1300px) {
                    height: auto;
                }
            }
            .caption {
                font-family: $avenir-heavy;
                font-size: 14px;
                line-height: calc(20 / 14);
                text-transform: uppercase;
                // margin-bottom: 8px;
                width: 244px;
            }
            .caption-address {
                font-size: 14px;
                color: #003322;
                transition: all 0.25s;
                display: block;
                @include cubic;
                &:hover {
                    opacity: 0.8;
                }
                @media (max-width: 1300px) {
                    margin-bottom: 80px;
                }
                @media (max-width: 768px) {
                    margin-bottom: 40px;
                }
            }
            .caption-copy {
                font-size: 14px;
                line-height: calc(20 / 14);
                width: 244px;
            }
        }
    }
    .keyline {
        border-bottom: 1px solid #e0e0de;
        margin-top: -1px;
        position: relative;
        z-index: 1;
        @media (max-width: 1300px) {
            margin-left: 40px;
            margin-right: 40px;
        }
    }
}

@keyframes compass {
    25% {
        transform: rotate(-48deg);
    }
    50% {
        transform: rotate(24deg);
    }
    75% {
        transform: rotate(-16deg);
    }
    100% {
        transform: rotate(32deg);
    }
}
