// Global Variables
$dark-green: #2d3d38;
$lighter-green: #3b4d47;
$magenta: #9b3465;
$dark-text: #003322;
$white: #fff;
$beige: #f5f1eb;
@mixin serif {
    font-family: 'freight-display-pro', serif;
    font-weight: 400;
    font-style: italic;
}
@mixin cubic {
    transition-timing-function: cubic-bezier(0.64, 0.04, 0.32, 1);
}
@mixin hover-supported {
    @media not all and (pointer: coarse) {
        &:hover {
            @content;
        }
    }
}
$avenir-book: 'Avenir-Book', sans-serif;
$avenir-medium: 'Avenir-Medium', sans-serif;
$avenir-heavy: 'Avenir-Heavy', sans-serif;
$avenir-roman: 'Avenir-Roman', sans-serif;
$avenir-book-oblique: 'Avenir-BookOblique', sans-serif;
