@import '../../App.scss';

.floorplan-details-stats {
    padding-top: 64px;
    .floorplan-details-info-container {
        display: flex;
        justify-content: center;
    }
    .floorplan-details-name {
        position: relative;
        font-size: 24px;
        line-height: calc(33 / 24);
        padding: 0 80px;
        font-family: $avenir-heavy;
        text-transform: uppercase;
        letter-spacing: 0.87px;
        color: $dark-text;
        @media (max-width: 768px) {
            padding: 0 16px;
        }
    }
    .floorplan-details-star-container {
        position: absolute;
        top: 0;
        right: 0;
        width: 72px;
        height: 100%;
        display: flex;
        align-items: center;
    }
    .floorplan-details-product-type {
        font-size: 20px;
        line-height: 1;
        color: $dark-text;
        @include serif;
        margin-bottom: 48px;
    }
    .floorplan-details-info-container {
        color: $dark-text;
        font-size: 16px;
        line-height: 1;
        font-family: $avenir-medium;
        text-transform: uppercase;
        margin-bottom: 32px;
    }
    .floorplan-details-info {
        padding: 0 24px;
        display: flex;
        align-items: center;
        @media (max-width: 768px) {
            padding: 0 8px;
            font-size: 12px;
        }
        &:first-of-type {
            padding-left: 0;
            svg {
                position: relative;
                top: -1px;
            }
        }
        &:last-of-type {
            padding-right: 0;
            &:after {
                display: none;
            }
        }
        svg {
            height: 16px;
            width: 24px;
            margin-right: 8px;
            fill: inherit;
        }
        position: relative;
        &:after {
            content: '';
            height: 24px;
            width: 1px;
            position: absolute;
            right: 0;
            background: #e0e0e0;
        }
    }
}
