@import '../../App.scss';

.compare-floorplans-side {
    width: calc(50% - 12px);
    height: calc(100vh - 144px);
    background: $white;
    overflow: hidden;
}
.compare-floorplans-side {
    .compare-floorplans-select-floorplan-container {
        display: none; // just for development until logic is hooked up
    }
    .compare-floorplans-list-container {
        // display: none; // just for development until logic is hooked up
    }
    .compare-floorplans-single-floorplan {
        display: none; // just for development until logic is hooked up
    }
}
