.survey-popup {
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: 200;
    left: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: auto;
    backdrop-filter: blur(10px);
    background-color: rgba(0, 0, 0, 0.6);

    .popup-interior {
        left: 0;
        right: 0;
        //top: 50%;
        //transform: translate(0, -50%);
        margin: auto;
        width: 90%;
        position: relative;
        background-color: white;
        max-width: 975px;

        * {
            color: unset;
        }

        .MSD-HTML-Interior {}

        h1,
        h2,
        h3,
        h4,
        h5,
        span,
        p,
        a {
            font-family: 'Helvetica Neue', sans-serif;
            color: unset;
        }

        @media (max-width: 811px) {  
            width:90%;
            top: 5%;
            transform: unset;
        }

        .close-button {
            position: absolute;
            top: -35px;
            right: 0;
            z-index: 10000;
            opacity: 1;

            p {
                color: white;
            }
        }

        .desktop-only {
            width: 100%;
            @media (max-width: 811px) {  
                display:none;
            }
        }

        .mobile-only {
            width: 100%;
            @media (min-width: 812px) {  
                display:none;
            }
        }

        .sub-header {
            font-size: 24px;
            color: #1C2E3B;
            letter-spacing: 0;
            text-align: center;
            line-height: 32px;

            @media (max-width: 811px) {  
                margin-left: 24px;
                margin-right: 24px;
            }
        }

        .grid-section {
            display: grid;
            grid-template-columns: 1fr 1fr;
            background-color: #F3F5F6;
            margin-left: 40px;
            margin-right: 40px;
            margin-bottom: 16px;
            padding-left: 16px;
            padding-right: 16px;
            padding-bottom: 16px;

            @media (max-width: 811px) {  
                margin-left: 24px;
                margin-right: 24px;
            }

            .left-top {
                grid-column: 1;
                border-right-style: solid;
                border-right-color: #DAE2E6;
                border-right-width: 1px;
                padding-left: 24px;
                padding-right: 24px;
                padding-top: 24px;
                padding-bottom: 32px;
                display: flex;
                align-items: flex-start;

                @media (max-width: 811px) {  
                    grid-column-start: 1;
                    grid-column-end: 3;
                    border-right-width: 0px;
                }

                .logo {
                    margin-right: 24px;
                }
            }

            .right-top {
                grid-column: 2;
                padding-left: 24px;
                padding-right: 24px;
                padding-top: 24px;
                padding-bottom: 32px;
                display: flex;
                align-items: flex-start;

                @media (max-width: 811px) {  
                    grid-column-start: 1;
                    grid-column-end: 3;
                }

                .logo {
                    margin-right: 24px;
                }
            }

            .top {
                .logo {
                    width: 64px;
                }

                @media (max-width: 811px) {  
                    p {
                        margin: 0;
                    }
                }
            }

            .bottom {
                grid-column-start: 1;
                grid-column-end: 3;
                border-top-style: solid;
                border-top-color: #DAE2E6;
                border-top-width: 1px;
                padding-top: 24px;
                display: flex;
                align-items: center;

                @media (max-width: 990px) {  
                    flex-direction: column;
                    border-top-width: 0px;
                }

                .detail-wrapper {
                    display: flex;

                    @media (max-width: 990px) {  
                        display: grid;
                        grid-template-columns: 1fr 1fr 1fr;
                        width: 100%;
                    }
                }

                .icon {
                    width: 16px;
                    padding-bottom: 16px;
                }

                .detail-box {
                    display: flex;
                    flex-direction: column;
                    padding: 16px;
                    min-width: 150px;
                    min-height: 150px;

                    @media(max-width: 768px) {
                        min-height: unset;
                        min-width: unset;
                    }

                    justify-content: space-between;
                    text-decoration: none;

                    >div {
                        font-family: Helvetica-Bold;
                        font-size: 13.11px;
                        color: #FFFFFF;
                        letter-spacing: 0;
                        line-height: 14.57px;
                    }
                }

                a {
                    &.detail-box {
                        .icon {
                            transition: 0.2s ease-in;
                        }

                        &:hover {
                            .icon {
                                margin-top: 8px;
                            }
                        }
                    }
                }

                p {
                    font-family: Helvetica-Bold;
                    font-size: 13.11px;
                    color: #FFFFFF;
                    letter-spacing: 0;
                    line-height: 14.57px;
                }

                .button {
                    font-family: 'calibri', sans-serif;
                    font-size: 18px;
                    color: #FFFFFF;
                    letter-spacing: 0;
                    text-align: center;
                    line-height: 32px;
                    background-color: #1C2E3B;
                    padding: 16px 48px;
                    text-decoration: none;
                    transition: 0.2s ease-in;
                    box-sizing: border-box;

                    @media (max-width: 990px) {  
                        padding: 16px;
                        width: 100%;
                    }

                    @media (min-width: 991px) {  
                        margin-left: 100px;
                    }


                    &:hover {
                        background-color: #436073;
                    }
                }
            }
        }

        .disclaimer {
            text-align: center;
            padding-bottom: 16px;
        }
    }
}


