@import '../../App.scss';

// Local Variables

$header-offset: 80px;

// .search-header-sizer {
//     display: flex;
//     align-items: center;
//     text-align: center;
//     @media (max-width: 1300px) {
//         flex-direction: column;
//         width: 100%;
//     }
// }

.search-header,
.search-header-sizer {
    position: fixed;
    top: 80px;
    left: 0;
    width: 100%;
    width: 100vw;
    height: 64px;
    background: $dark-green;
    z-index: 3;
    display: flex;
    align-items: center;
    text-align: center;
    @media (max-width: 1300px) {
        flex-direction: column;
        .main-button {
            display: none;
        }
    }
    .search-header-radio-container {
        border-right: 1px solid #465c54;
        padding: 0 48px;
        height: 32px;
        display: flex;
        align-items: center;
        .radio-button {
            cursor: pointer;
        }
        svg {
            fill: $white;
            height: 16px;
            width: 24px;
            margin-right: 24px;
            position: relative;
            top: -1px;
            @media (max-width: 1300px) {
                margin-right: 16px;
            }
            &.block-icon {
                fill-opacity: 0;
                stroke: #fff;
            }
            &.down-arrow {
                margin-right: 0;
                margin-left: 24px;
                width: 12px;
            }
        }
        .radio-active {
            .radio-fill {
                fill: $white;
                transition: all 0.25s;
                @include cubic;
            }
        }
        button {
            padding: 0;
        }
        .radio-active {
            &:hover {
                .radio-fill {
                    fill: #c9d4d1;
                }
            }
        }
    }
    div:nth-last-child(2) {
        border-right: none;
    }
    .input-search-container {
        display: flex;
        flex-grow: 1;
        height: 100%;
        @media (max-width: 1300px) {
            width: 100%;
        }
    }
    input {
        background: #1e2e29;
        border: none;
        flex-grow: 1;
        outline: none;
        line-height: 32px;
        height: 100%;
        box-sizing: border-box;
        font-family: $avenir-book;
        line-height: 80px;
        text-transform: uppercase;
        font-size: 13px;
        letter-spacing: 0.87px;
        color: $white;
        padding-left: 48px;
        @media (max-width: 1300px) {
            height: 64px;
            width: 100%;
            display: flex;
            padding-left: 24px;
        }
        &::placeholder {
            color: $white;
        }
    }
    .search-icon-container {
        background: #1e2e29;
        height: 64px;
        display: flex;
        align-items: center;
        padding: 0 48px 0;
        @media (max-width: 1300px) {
            padding: 0 24px 0;
        }
        svg {
            width: 24px;
        }
    }
    button {
        font-family: $avenir-book;
        text-transform: uppercase;
        font-size: 13px;
        letter-spacing: 0.87px;
        color: $white;
        background: transparent;
        margin-left: 48px;
        height: 100%;
        display: flex;
        align-items: center;
        transition: all 0.25s;
        @include cubic;
        padding: 0 48px;
        &:first-of-type {
            margin-left: 0;
        }
        svg {
            transition: all 0.25s;
            @include cubic;
        }
        .down-arrow-path {
            transition: all 0.25s;
            @include cubic;
        }
        &:hover {
            color: #c9d4d1;
            svg {
                fill: #c9d4d1;
                .down-arrow-path {
                    stroke: #c9d4d1;
                }
            }
        }
    }
    .search-header-icon {
        height: 20px;
        margin-right: 24px;
    }
    .search-header-down-arrow {
        margin-left: 24px;
    }
    .search-header-compare-floorplans {
        height: 100%;
    }
    @media (max-width: 1600px) {
        .search-header-compare-floorplans {
            .main-button {
                padding: 0 24px;
            }
        }
        .search-header-dropdown-container {
            .button {
                padding: 0 24px;
            }
        }
        .search-header-radio-container {
            padding: 0 24px;
            .radio-button {
                margin-left: 20px;
            }
        }
    }
    @media (max-width: 1300px) {
        top: 56px;
    }
    .search-header-radio-container {
        @media (max-width: 1300px) {
            width: 100%;
            height: 64px;
            display: flex;
            justify-content: flex-start;
            background: $dark-green;
            .radio-filter {
                height: 64px;
            }
        }
    }
    .search-header-dropdown-container {
        @media (max-width: 1300px) {
            width: 100%;
            height: 64px;
            display: flex;
            justify-content: flex-start;
            background: $dark-green;
            .button {
                width: 100%;
                justify-content: space-between;
            }
            .dropdown {
                top: 64px;
                span {
                    padding: 0 24px;
                }
                li {
                    padding: 8px 0;
                }
            }
            svg {
                margin-right: 16px;
            }
            .down-arrow {
                @media (max-width: 1300px) {
                    margin-right: 0 !important;
                }
            }
            &:not(.mobile-dropdown-filter) {
                flex-direction: column;
                height: auto;
                min-height: 64px;
                .button {
                    height: 64px;
                    .dropdown-left {
                        padding-left: 20px;
                    }
                    .down-arrow {
                        margin-right: 24px !important;
                    }
                }
                .dropdown {
                    position: static;
                }
            }
        }
    }
    .mobile-dropdown-filter {
        display: none;
        @media (max-width: 1300px) {
            display: flex;
            position: fixed;
            top: 56px;
        }
    }
}

.mobile-dropdown-container {
    @media (max-width: 1300px) {
        top: 120px;
        position: fixed;
        overflow: hidden;
        height: 0;
        transition: height 0.25s;
        @include cubic;
        // max-height: calc(100% - 120px);
        // overflow-y: auto;
        // width: 100vw;
    }
}

.search-header-sizer {
    @media (max-width: 1300px) {
        position: static;
        height: auto;
    }
}
