@import '../../App.scss';

// Local Variables

$header-offset: 192px;

.grid-viewport {
    height: calc(100vh - #{$header-offset});
    top: $header-offset;
    overflow-y: auto;
    cursor: grab;
    position: fixed;
    width: 100%;
    @media (max-width: 1300px) {
        height: auto;
        position: static;
        padding-top: 168px;
    }
}

.floorplans-grid {
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;
}
