@import '../../App.scss';

// Local Variables

$header-offset: 80px;
$mobile-header-offset: 56px;

.features-and-finishes-viewport {
    overflow-y: hidden;
    height: 100%;
    display: flex;
    cursor: grab;
    @media (max-width: 1300px), (max-height: 700px) {
        height: auto;
        cursor: default;
    }
    @media (max-width: 1300px), (max-height: 700px) {
        overflow-y: auto;
        overflow-x: hidden;
    }
    .features-and-finishes {
        padding-top: $header-offset;
        height: calc(100% - #{$header-offset});
        display: flex;
        @media (max-width: 1300px) {
            padding-top: $mobile-header-offset;
            height: auto;
            position: absolute;
            overflow-x: hidden;
            width: 100%;
        }
        @media (max-width: 1300px), (max-height: 700px) {
            flex-direction: column;
            .drag-info {
                display: none;
            }
        }
    }
}
