@import '../../App.scss';

// Local Variables

$header-offset: 144px;
$mobile-header-offset: 120px;

.gallery-renderings {
    padding-top: $header-offset;
    height: calc(100vh - #{$header-offset});
    @media (max-width: 1300px) {
        padding-top: $mobile-header-offset;
        height: calc(100vh - #{$mobile-header-offset});
    }
}
