@import '../../App.scss';

.contact-form {
    color: #f1f7f2;
    font-size: 12px;
    line-height: calc(16 / 12);
    letter-spacing: 0.8px;
    text-transform: uppercase;
    @media (max-width: 1600px) {
        font-size: 10px;
    }
    @media (max-height: 950px) {
        font-size: 10px;
    }
    .input-section {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 32px;
        @media (max-width: 768px) {
            margin-bottom: 16px;
        }
    }
    .radio-section {
        margin-bottom: 32px;
        @media (max-height: 768px) {
            margin-bottom: 24px;
        }
    }
    .select-section-two-columns {
        margin-bottom: 32px;
        display: flex;
        justify-content: space-between;
        @media (max-width: 768px) {
            flex-direction: column;
        }
    }
    .select-section-one-column {
        margin-bottom: 32px;
        p {
            margin-bottom: 8px;
            text-transform: none;
            text-align: center;
            font-family: $avenir-book;
            font-size: 16px;
            line-height: 1.5;
            @media (max-width: 768px) {
                font-size: 14px;
            }
        }
    }
    .submit-section {
        display: flex;
        justify-content: space-between;
        @media (max-width: 768px) {
            flex-direction: column;
        }
    }
    .required-fields {
        padding-left: 50%;
        width: 50%;
        text-align: center;
        text-transform: none;
        margin-top: 14px;
        font-size: 12px;
    }
    .form-errors {
        text-align: center;
        margin-top: 20px;
        text-transform: none;
        font-size: 16px;
    }
}
.thank-you-message {
    color: #fff;
    margin-top: 80px;
    margin-bottom: 80px;
    font-size: 48px;
    text-align: center;
    flex: 1;
    display: flex;
    align-items: center;
    line-height: 1.5;
    @include serif;
}
