@import '../../App.scss';

.radio-button {
    display: flex;
    align-items: center;
    font-family: $avenir-book;
    text-transform: uppercase;
    font-size: 13px;
    letter-spacing: 0.87px;
    color: $white;
    background: transparent;
    margin-left: 48px;
    height: 100%;
    transition: all 0.25s;
    cursor: pointer;
    label {
        cursor: pointer;
    }
    @include cubic;

    &:first-of-type {
        margin-left: 0;
    }

    svg {
        transition: all 0.25s;
        @include cubic;

        .radio-fill {
            transition: all 0.25s;
            @include cubic;
            opacity: 0;
            fill: $white;
        }
    }
    .down-arrow-path {
        transition: all 0.25s;
        @include cubic;
    }

    label {
        display: block;
    }

    &.radio-active {
        svg {
            .radio-fill {
                opacity: 1;
            }
        }
    }

    &:hover {
        color: #c9d4d1;
        svg {
            fill: #c9d4d1;
            .down-arrow-path {
                stroke: #c9d4d1;
            }
        }
    }
}
