@import '../../App.scss';

.tabbed-layout {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    .tabbed-titles {
        display: flex;
        justify-content: center;
        color: $dark-text;
        font-size: 12px;
        line-height: calc(16 / 12);
        font-family: $avenir-roman;
        text-transform: uppercase;
        color: #7a7367;
        letter-spacing: 0.6px;
        border-bottom: 1px solid #e0e0e0;

        .tabbed-title {
            padding: 16px 0;
            margin: 0 20px;
            cursor: pointer;
            font-family: $avenir-book;

            &.active {
                color: $dark-text;
                border-bottom: 1px soild $dark-text;
            }

            &:hover {
                color: $dark-text;
            }
        }
    }

    .tabbed-content {
        flex: 1 1 auto;
        position: relative;
        display: flex;
        max-height: 100%;
        max-width: 100%;

        .tabbed-container {
            display: none;
            flex: 1 1 auto;
            @media (max-width: 1900px) {
                .floorplan-details-info {
                    padding: 0 8px;
                    font-size: 12px;
                }
                .main-button {
                    width: 280px !important;
                }
            }

            &.active {
                display: flex;
            }

            &.col-cont {
                flex-direction: column;
            }

            // .grid-floorplan-image-container {
            //     flex: 1 1 auto;
            //     position: relative;
            //     display: flex;
            //     flex-direction: row;
            //     justify-content: center;
            //     align-items: flex-end;
            //     padding-top: 10px;
            //     max-width: 100%;
            //     max-height: 100%;
            //     img {
            //         width: auto;
            //         height: auto;
            //         max-width: 100%;
            //         max-height: calc(100% - 64px);
            //     }

            //     .floorplan-details-floorplan-container {
            //         display: flex;
            //         flex-direction: column;
            //         justify-content: flex-end;
            //         position: relative;

            //         &:nth-child(1) {
            //             width: 33.7474%;
            //             height: 100%;
            //         }

            //         &:nth-child(2) {
            //             width: 26.9151%;
            //             height: 100%;
            //             margin-left: 11.2836%;
            //         }
            //     }
            // }
        }
    }
    .tabbed-content {
        &.details {
            height: calc(100% - 48px);
            .tabbed-container {
                width: 100%;
            }
            .grid-floorplan-image-container {
                width: 100%;
                display: flex;
                justify-content: center;
                padding: 56px;
                height: 100%;
                box-sizing: border-box;
                align-items: flex-end;
                @media (max-width: 1840px) {
                    padding-bottom: 120px;
                }
                img {
                    max-height: calc(100% - 64px);
                    max-width: 100%;
                    width: 100vw;
                }
            }
            .floorplan-details-floorplan-container {
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                max-width: 300px;
                align-items: center;
                margin-left: 120px;
                @media (max-width: 1300px) {
                    margin-left: 40px;
                }
                &:first-of-type {
                    margin-left: 0;
                }
            }
        }
        &.compare {
            .grid-floorplan-image-container {
                padding: 56px 112px;
                align-items: center;
            }
        }
        &.grid {
            // height: calc(100vh - 454px);
            padding: 48px;
            box-sizing: border-box;
            position: relative;
            .grid-floorplan-image-container {
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                img {
                    max-width: 80%;
                    max-height: 80%;
                    transform: translateY(-24px);
                }
            }
        }
    }
}
