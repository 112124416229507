@import '../../App.scss';

.consent {
    width: calc(50% - 12px);
    font-family: $avenir-book;
    font-size: 12px;
    line-height: calc(16 / 14);
    letter-spacing: 0.93px;
    display: flex;
    align-items: center;
    text-transform: none;
    cursor: pointer;
    transition: color 0.25s;
    @media (max-width: 768px) {
        width: 100%;
        margin-bottom: 24px;
    }
    .consent-text {
        margin-left: 8px;
    }
    .checkmark {
        fill: $dark-green;
        transition: all 0.25s;
        @include cubic;
    }
    &.active {
        .checkmark {
            fill: $white;
        }
    }
    .box {
        transition: all 0.25s;
    }
    &.error {
        // color: red;
        .box {
            stroke: red;
        }
    }
}
