@import '../../App.scss';

.floorplan-details-tab {
    padding: 16px 0;
    margin: 0 20px;
    @media (max-width: 500px) {
        padding: 8px 0;
        margin: 0 16px;
    }
    cursor: pointer;
    margin-bottom: -1px;
    &.active-tab {
        color: $dark-text;
        border-bottom: 1px solid $dark-text;
    }
    &:hover {
        color: $dark-text;
    }
}
