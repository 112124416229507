@import '../../App.scss';

// Local Variables

$header-offset: 80px;
$mobile-header-offset: 56px;

.about {
    padding-top: $header-offset;
    height: calc(100vh - #{$header-offset});
    @media (max-width: 1300px) {
        padding-top: $mobile-header-offset;
        height: calc(100vh - #{$mobile-header-offset});
    }
    background: $lighter-green;
    display: flex;
    justify-content: space-between;
    overflow: hidden;
    .about-section {
        width: calc(50% - 12px);
        height: 100%;
        background: #f9faf1;
        position: relative;
        p {
            font-family: $avenir-book;
            font-size: 18px;
            line-height: calc(28 / 18);
            color: $dark-text;
            @media (max-width: 1600px) {
                font-size: 14px;
            }
            @media (max-height: 950px) {
                font-size: 14px;
            }
        }
    }
    .about-left {
        padding: 104px 15% 104px 25%;
        @media (max-width: 1600px) {
            padding-top: 60px;
        }
        @media (max-height: 950px) {
            padding-top: 60px;
        }
    }
    .about-right {
        padding: 104px 25% 104px 15%;
        @media (max-width: 1600px) {
            padding-top: 60px;
        }
        @media (max-height: 950px) {
            padding-top: 60px;
        }
    }
    .about-logos {
        margin-bottom: 96px;
        height: 56px;
        @media (max-width: 1600px) {
            margin-bottom: 32px;
        }
        @media (max-height: 950px) {
            margin-bottom: 32px;
        }
    }
    .about-flowers-left {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 20%;
        mix-blend-mode: multiply;
    }
    .about-flowers-right {
        position: absolute;
        bottom: 0;
        right: 0;
        width: 20%;
        mix-blend-mode: multiply;
    }
    @media (max-height: 700px), (max-width: 1300px) {
        height: auto;
        display: block;
        .about-section {
            width: 100%;
            display: flex;
            align-items: center;
            flex-direction: column;
            margin-bottom: 24px;
            // this next part feels hacky, make less gross when there's time
            &:nth-child(3) {
                margin-bottom: 0;
            }
        }
        .about-left,
        .about-right {
            padding: 60px 24px;
            max-width: 600px;
        }
        .about-left {
        }
        .about-flowers-left,
        .about-flowers-right {
            display: none;
        }
    }
}
