@import '../../App.scss';

.compare-table {
    .floorplans-list-item {
        &:nth-last-child(8) {
            border-bottom: 1px solid #e0e0e0;
        }
    }
}

.floorplans-list-item {
    font-family: $avenir-medium;
    display: flex;
    align-items: center;
    font-size: 16px;
    line-height: calc(22 / 16);
    text-transform: uppercase;
    color: #003322;
    padding: 8px 8px 8px 0;
    border-bottom: 1px solid #e0e0e0;
    height: 100px;
    box-sizing: border-box;
    cursor: pointer;
    &:nth-last-child(-n + 5) {
        border-bottom: none;
    }
    .list-rendering {
        height: 84px;
    }
    &.list-name {
        font-family: $avenir-heavy;
    }
    &.star-container {
        justify-content: center;
        fill: #7a7367;
        stroke: #7a7367;
    }
    &.right-arrow-container {
        justify-content: flex-end;
        padding-right: 0;
        img {
            cursor: pointer;
        }
    }
}
