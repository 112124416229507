@import '../../App.scss';

// Local Variables

$header-offset: 0;

.top-row {
    @supports (position: sticky) {
        position: sticky;
        top: $header-offset;
    }
    font-family: $avenir-medium;
    display: flex;
    align-items: center;
    font-size: 16px;
    line-height: calc(22 / 16);
    text-transform: none;
    color: #7a7367;
    padding: 0 8px 0 0;
    box-sizing: border-box;
    height: 64px;
    z-index: 1;
}

.compare-floorplans {
    .top-row {
        margin-bottom: 128px;
        top: 128px;
    }
}
