@import '../../App.scss';

.mobile-footer {
    justify-content: center;
    background: #f9faf1;
    border-bottom: 8px solid #2d3d38;
    display: none;
    &.mobile-footer-show {
        display: flex;
    }
    .mobile-footer-container {
        width: 296px;
    }
    .corporate-logos {
        margin-top: 56px;
        margin-bottom: 56px;
        display: flex;
        justify-content: center;
        a {
            display: inline-block;
            &:first-of-type {
                margin-right: 24px;
            }
            &:last-of-type {
                margin-left: 24px;
            }
        }
        img {
            height: 32px;
            width: auto;
        }
        .separator {
            display: inline-block;
            width: 1px;
            height: 32px;
            background: #6c6e70;
        }
    }
    .contact-social {
        display: flex;
        justify-content: center;
        margin-bottom: 56px;
        li {
            margin-right: 24px;
            &:last-of-type {
                margin-right: 0;
            }
        }
        svg {
            &:hover {
                path {
                    fill: $dark-text;
                }
            }
        }
        svg,
        path {
            fill: $magenta;
            transition: all 0.25s;
            @include cubic;
        }
    }
    .mobile-copyright,
    .mobile-footer-links {
        font-family: $avenir-book;
        color: #003322;
        font-size: 12px;
        line-height: 16px;
        text-align: center;
    }
    .mobile-footer-links {
        display: flex;
        justify-content: space-between;
        margin-top: 8px;
        margin-bottom: 32px;
        a {
            color: #003322;
            font-size: 10px;
            line-height: 14px;
            letter-spacing: 0.67px;
            text-transform: uppercase;
        }
    }
}
