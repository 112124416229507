@import '../../App.scss';

.main-button {
    font-family: $avenir-book;
    background: $magenta;
    color: $white;
    font-size: 13px;
    line-height: calc(18 / 13);
    text-transform: uppercase;
    letter-spacing: 0.87px;
    padding: 24px 40px;
    display: inline-block;
    transition: all 0.25s;
    @include cubic;
    border: 1px solid $magenta;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    text-align: center;
    cursor: pointer;
    svg {
        margin-right: 24px;
        position: relative;
        top: -2px;
        height: 16px;
        width: 24px;
        transition: all 0.25s;
        @include cubic;
        fill: $white;
        * {
            fill: $white;
        }
    }
    &:hover {
        background: $white;
        color: $magenta;
        svg {
            fill: $magenta;
            * {
                fill: $magenta;
            }
        }
        .download-icon {
            .stroke {
                stroke: $magenta;
            }
        }
    }
    &.ghost {
        background: $white;
        color: $magenta;
        svg {
            fill: $magenta;
            * {
                fill: $magenta;
            }
        }
        &:hover {
            background: $magenta;
            color: $white;
            svg {
                fill: $white;
                * {
                    fill: $white;
                }
            }
        }
    }
    &.svg-absolute {
        svg {
            position: absolute;
            left: 24px;
            top: 50%;
            transform: translateY(-50%);
        }
    }
}
