@import '../../App.scss';

.drag-info {
    position: fixed;
    top: 50%;
    transform: translateY(-50%);
    right: 40px;
    width: 152px;
    height: 152px;
    border-radius: 50%;
    background: $magenta;
    font-family: $avenir-book;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $white;
    font-size: 14px;
    letter-spacing: 0.93px;
    text-transform: uppercase;
    z-index: 1;
    transition: all 0.25s;
    @include cubic;
    span {
        position: relative;
        svg {
            position: absolute;
            top: 32px;
            left: 50%;
            transform: translateX(-50%);
        }
    }
}
