@import '../../App.scss';

.features-and-finishes-warranty {
    .warranty-column {
        break-inside: avoid-column;
    }
    .warranty-subheader {
        font-family: $avenir-medium;
        font-size: 24px;
        line-height: 1.333;
        margin-bottom: 48px;
    }
    .warranty-section-header {
        font-family: $avenir-heavy;
        text-transform: uppercase;
        font-size: 18px;
        line-height: 1.333;
        margin-bottom: 24px;
    }
    .sublist {
        background: #e4e6d8;
        padding: 24px;
        padding-left: 48px;
        margin-left: -24px;
    }
}
