@import '../../App.scss';

.dropdown {
    li {
        margin-bottom: 16px;
        &:last-of-type {
            margin-bottom: 0;
        }
    }
    svg {
        width: 24px;
        height: 24px;
        min-width: 24px;
        min-height: 24px;

        .checkmark {
            fill: transparent;
        }
        &.checkbox-active {
            .checkmark {
                fill: #c9d4d1;
            }
        }
    }
    button {
        font-size: 12px;
        letter-spacing: 0.8px;
        color: $white;
        display: block;
    }
    span {
        font-size: 12px;
        letter-spacing: 0.8px;
        color: $white;
        display: block;
        font-family: $avenir-book;
        text-transform: uppercase;
        background-color: transparent;
        height: 100%;
        transition: all;
        padding: 0 48px;
        white-space: nowrap;
        display: flex;
        align-items: center;
        cursor: pointer;
        transition: all 0.25s;
        @include cubic;
        &:first-of-type {
            margin-left: 0;
        }
        svg {
            transition: all 0.25s;
            @include cubic;
        }
        .down-arrow-path {
            transition: all 0.25s;
            @include cubic;
        }
        label {
            display: block;
            cursor: pointer;
        }
        .box {
            transition: all 0.25s;
            @include cubic;
        }
        .checkmark {
            transition: all 0.25s;
            @include cubic;
        }
        &:hover {
            color: #c9d4d1;
            svg {
                fill: #c9d4d1;
                .down-arrow-path {
                    stroke: #c9d4d1;
                }
            }
            .box {
                stroke: #c9d4d1;
            }
            .checkbox-active {
                .checkmark {
                    fill: #c9d4d1;
                }
            }
        }
    }
}
