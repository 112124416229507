@import '../../App.scss';

.compare-floorplans-right {
    margin-left: 24px;
    .compare-floorplans-single-floorplan {
        flex-direction: row-reverse;
    }
}

.compare-floorplans-single-floorplan {
    display: flex;
    height: 100%;
    position: relative;
    .compare-floorplans-reselect {
        position: fixed;
        top: 80px;
        height: 64px;
        width: calc(50% - 12px);
        background: $beige;
        color: $magenta;
        font-size: 13px;
        line-height: 64px;
        letter-spacing: 0.87px;
        text-transform: uppercase;
        padding-right: 40px;
        font-family: $avenir-book;
        box-sizing: border-box;
        text-align: right;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        span {
            cursor: pointer;
            display: flex;
            align-items: center;
            transition: all 0.25s;
            @include cubic;
            &:hover {
                color: $dark-text;
                svg {
                    path {
                        fill: $dark-text;
                    }
                }
            }
        }
        svg {
            position: relative;
            top: -2px;
            margin-right: 16px;
            path {
                transition: all 0.25s;
                @include cubic;
                fill: $magenta;
            }
        }
    }
}
