@import '../../App.scss';

// Local Variables

$header-offset: 80px;
$mobile-header-offset: 56px;

.floorplan-details {
    padding-top: $header-offset;
    display: flex;
    flex-wrap: wrap;
    height: calc(100vh - #{$header-offset});
    @media (max-width: 1300px) {
        padding-top: $mobile-header-offset;
        height: auto;
        flex-direction: column;
    }
    .floorplan-details-left-panel {
        border: 1px solid #e0e0e0;
        width: 25%;
        display: flex;
        box-sizing: border-box;
        flex-direction: column;
        text-align: center;
        position: relative;
        .floorplan-details-tab {
            display: none;
        }
        // .tabbed-container {
        //     justify-content: center;
        //     .floorplan-details-stats {
        //         padding-top: 8px;
        //     }
        // }
        @media (max-width: 1300px) {
            width: 100%;
            padding-bottom: 80px;
            border: none;
        }
        @media (max-width: 768px) {
            .main-button {
                width: 296px !important;
                padding: 16px !important;
                svg {
                    position: static;
                    margin-right: 16px;
                    transform: none;
                }
            }
        }
    }
    .floorplan-details-stats {
        padding-top: 64px;
    }
    .floorplan-details-info-container {
        display: flex;
        justify-content: center;
    }
    .floorplan-details-name {
        position: relative;
        font-size: 24px;
        line-height: calc(33 / 24);
        padding: 0 80px;
        font-family: $avenir-heavy;
        text-transform: uppercase;
        letter-spacing: 0.87px;
        color: $dark-text;
    }
    .floorplan-details-star-container {
        position: absolute;
        top: 0;
        right: 0;
        width: 72px;
        height: 100%;
        display: flex;
        align-items: center;
    }
    .floorplan-details-product-type {
        font-size: 20px;
        line-height: 1;
        color: $dark-text;
        @include serif;
        margin-bottom: 48px;
    }
    .floorplan-details-info-container {
        color: $dark-text;
        font-size: 16px;
        line-height: 1;
        font-family: $avenir-medium;
        text-transform: uppercase;
        margin-bottom: 32px;
    }
    .floorplan-details-info {
        padding: 0 24px;
        display: flex;
        align-items: center;
        @media (max-width: 768px) {
            padding: 0 16px;
            font-size: 14px;
        }
        &:first-of-type {
            padding-left: 0;
        }
        &:last-of-type {
            padding-right: 0;
        }
        img {
            height: 20px;
            margin-right: 8px;
        }
    }
    .floorplan-details-elevation-container,
    .floorplan-details-floor-container {
        display: flex;
        justify-content: center;
        color: $dark-text;
        font-size: 12px;
        line-height: calc(16 / 12);
        font-family: $avenir-roman;
        text-transform: uppercase;
        color: #7a7367;
        letter-spacing: 0.6px;
        border-bottom: 1px solid #e0e0e0;
    }
    .floorplan-details-elevation,
    .floorplan-details-floor {
        padding: 16px 0;
        margin: 0 20px;
        cursor: pointer;
        margin-bottom: -1px;
        &.active-elevation,
        &.active-floor {
            color: $dark-text;
            border-bottom: 1px solid $dark-text;
        }
        &:hover {
            color: $dark-text;
        }
    }
    .floorplan-details-caption {
        padding-bottom: 40px;
        font-size: 16px;
        line-height: calc(24 / 16);
        font-family: $avenir-heavy;
        color: $dark-text;
        text-transform: uppercase;
    }
    .secondary-button {
        color: $magenta;
        background: $white;
        width: 320px;
        text-align: center;
        text-transform: uppercase;
        font-family: $avenir-book;
        letter-spacing: 0.87px;
        font-size: 13px;
        line-height: calc(18 / 13);
        margin-bottom: 8px;
        padding: 16px 48px;
        align-self: center;
        border: 1px solid $magenta;
        box-sizing: border-box;
        transition: all 0.25s;
        @include cubic;
        position: relative;
        &:hover {
            background: $magenta;
            color: $white;
            svg {
                #Line {
                    fill: $magenta;
                }
                #Path {
                    stroke: $magenta;
                }
                #Icon-Bottom {
                    fill: $magenta;
                }
            }
        }
        svg {
            position: absolute;
            left: 20px;
            top: 50%;
            transform: translateY(-50%);
            transition: all 0.25s;
            @include cubic;
        }
    }
    .back-to-floorplans {
        position: absolute;
        bottom: 160px;
        width: 100%;
        text-align: center;
        padding: 0px 8px;
        color: #7b7366;
        text-transform: uppercase;
        font-family: $avenir-book;
        font-size: 13px;
        line-height: calc(18 / 13);
        letter-spacing: 0.87px;
        @media (max-width: 1300px) {
            display: none;
        }
        img {
            display: flex;
            transition: all 0.25s;
            @include cubic;
            &:hover {
                color: $magenta;
                .line {
                    fill: $magenta;
                }
                .arrow {
                    stroke: $magenta;
                }
            }
        }

        svg {
            margin-right: 16px;
        }
        &:hover {
            color: $magenta;
            .line {
                fill: $magenta;
            }
            .arrow {
                stroke: $magenta;
            }
        }
    }
    .flowers-left {
        position: absolute;
        bottom: 0;
        left: 0;
        max-height: 160px;
        max-width: 40%;
        @media (max-width: 1300px) {
            display: none;
        }
    }
    .flowers-right {
        position: absolute;
        bottom: 0;
        right: 0;
        max-height: 160px;
        max-width: 40%;
        @media (max-width: 1300px) {
            display: none;
        }
    }
    .floorplan-details-center-panel {
        border: 1px solid #e0e0e0;
        border-left: none;
        width: 50%;
        display: flex;
        box-sizing: border-box;
        flex-direction: column;
        text-align: center;
        position: relative;
        height: 100%;
        @media (max-width: 1300px) {
            width: 100%;
            border: none;
        }
        // padding-bottom: 50px;
    }
    .floorplan-details-floorplans {
        padding: 56px 152px;
        display: flex;
        justify-content: center;
        height: calc(100% - 161px);

        // > .floorplan-details-floorplan-container {
        //     &:nth-child(1) {
        //         width: 33.7474%;
        //     }

        //     &:nth-child(2) {
        //         width: 26.9151%;
        //     }

        //     img {
        //         height: auto;
        //     }
        // }
    }
    .floorplan-details-floorplan-name {
        font-family: $avenir-heavy;
        text-transform: uppercase;
        color: $dark-text;
        font-size: 16px;
        line-height: 1.5;
        margin-top: 40px;
        text-align: center;
    }
    .zoom-container {
        position: absolute;
        bottom: 30px;
        right: 40px;
        width: 56px;
        height: 56px;
        background: $magenta;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        transition: all 0.25s;
        @include cubic;
        border: 1px solid $magenta;
        &:hover {
            color: $magenta;
            background: $white;
            border: 1px solid $magenta;
            .magnifier {
                stroke: $magenta;
            }
            .sign {
                fill: $magenta;
            }
        }
    }
    .floorplan-details-right-panel {
        border: 1px solid #e0e0e0;
        border-left: none;
        width: 25%;
        display: flex;
        box-sizing: border-box;
        flex-direction: column;
        text-align: center;
        position: relative;
        padding: 50px 48px;
        @media (max-width: 1300px) {
            width: 100%;
            border: none;
            border-top: 1px solid #e0e0e0;
            padding-top: 32px;
        }
        .main-button {
            @media (max-width: 1300px) {
                display: none;
            }
        }
        .secondary-button {
            width: 100%;
            margin-bottom: 60px;
            cursor: pointer;
        }
    }
    .floorplan-details-rendering-container {
        img {
            width: 100%;
        }
    }
    .floorplan-details-block-container {
        padding-top: 38px;
        padding-bottom: 29px;
        padding-right: 32px;
        padding-left: 32px;
        background-color: #f5f1eb;
        text-align: left;
        margin-bottom: auto;
        @media (max-width: 1300px) {
            margin-bottom: 80px;
        }

        .floorplan-details-block-container-heading {
            text-transform: uppercase;
            color: #003322;
            margin-bottom: 6px;
            font-family: $avenir-heavy;
            font-size: 18px;
            letter-spacing: 0.65px;
        }

        .floorplan-details-block-container-block {
            padding-top: 13px;
            padding-bottom: 13px;

            .label {
                font-family: $avenir-heavy;
                margin-right: 32px;
                font-size: 14px;
                letter-spacing: 0.51px;
                color: #003322;
            }

            .units {
                font-family: $avenir-book;
                color: #003322;
                line-height: 1;
                font-size: 16px;
            }
        }
        > .floorplan-details-block-container-block:not(:last-child) {
            border-bottom: 1px #e6e2dc solid;
        }
    }
    .floorplan-details-rendering-name {
        font-family: $avenir-medium;
        font-size: 13px;
        line-height: calc(18 / 13);
        letter-spacing: 0.6px;
        color: $dark-text;
        text-transform: uppercase;
        padding: 10px;
        margin-bottom: 32px;
    }
    .floorplan-details-site-plan {
        width: 100%;
    }
    .floorplan-siteplan-container {
        margin-bottom: 70px;
    }
    .floorplan-details-compass {
        position: absolute;
        bottom: 38px;
        left: 40px;
        width: 60px;
        @media (max-width: 1600px) {
            display: none;
        }
    }
    .go-to-siteplan {
        position: absolute;
        bottom: 55px;
        left: 50%;
        transform: translateX(-50%);
        color: #7b7366;
        text-transform: uppercase;
        font-family: $avenir-book;
        font-size: 13px;
        line-height: calc(18 / 13);
        letter-spacing: 0.87px;
        width: 100%;
        transition: all 0.25s;
        @include cubic;
        &:hover {
            color: $magenta;
            .line {
                fill: $magenta;
            }
            .arrow {
                stroke: $magenta;
            }
        }
        svg {
            margin-left: 16px;
            position: absolute;
            @media (max-width: 1600px) {
                position: static;
            }
        }
    }
    .floorplan-details-fullscreen {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: $white;
        display: none;
        .floorplan-details-floorplans {
            height: calc(100% - 112px);
        }
    }
    .floorplan-details-fullscreen-close {
        background: $magenta;
        color: #fff;
        font-family: $avenir-book;
        text-transform: uppercase;
        padding: 16px 24px;
        position: absolute;
        top: 0;
        right: 0;
        font-size: 13px;
        line-height: calc(18 / 13);
        letter-spacing: 0.87px;
        display: flex;
        align-items: center;
        cursor: pointer;
        img {
            margin-right: 8px;
            position: relative;
            top: -1px;
        }
    }
    .floorplan-details-back-container,
    .floorplan-details-next-container {
        font-family: $avenir-medium;
        font-size: 13px;
        line-height: calc(18 / 13);
        letter-spacing: 0.6px;
        color: $dark-text;
        text-transform: uppercase;
        display: flex;
        align-items: center;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        button {
            background: $magenta;
            width: 56px;
            height: 56px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 24px;
            display: inline-block;
            transition: all 0.25s;
            @include cubic;
        }
    }
}
