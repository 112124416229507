@import '../../App.scss';

.features-and-finishes-renderings {
    background: #f9faf1;
    display: flex;
    position: relative;
    overflow: hidden;
    @media (max-width: 1300px), (max-height: 700px) {
        overflow: initial;
        flex-direction: column;
        padding-bottom: 80px;
    }
    .features-renderings-flowers-left {
        position: absolute;
        bottom: 0;
        left: 0;
        max-width: 120px;
        max-height: calc(100% - 320px);
        mix-blend-mode: multiply;
        @media (max-width: 768px) {
            display: none;
        }
    }
    .slider-left {
        padding-left: 160px;
        padding-top: 120px;
        padding-right: 23px;
        width: 448px;
        flex-shrink: 0;
        @media (max-height: 800px) {
            padding-top: 64px;
        }
        @media (max-width: 1300px), (max-height: 700px) {
            width: 100%;
            max-width: 880px;
            box-sizing: border-box;
        }
        @media (max-width: 768px) {
            padding-left: 32px;
            padding-right: 32px;
            max-width: 600px;
        }
    }
    .slider-copy {
        @include serif;
        font-size: 36px;
        line-height: calc(40 / 36);
        color: $dark-text;
        margin-bottom: 96px;
        position: relative;
        @media (max-height: 950px) {
            font-size: 28px;
            margin-bottom: 56px;
        }
        @media (max-width: 768px) {
            margin-left: 80px;
            font-size: 18px;
        }
    }
    .big-number {
        position: absolute;
        font-size: 220px;
        top: 0;
        left: 0;
        transform: translateX(calc(-100% - 24px)) translateY(-80px);
        @media (max-height: 950px) {
            font-size: 120px;
            transform: translateX(calc(-100% - 24px)) translateY(-40px);
        }
        @media (max-width: 768px) {
            font-size: 120px;
            transform: translateX(calc(-100% - 24px)) translateY(-48px);
        }
    }
    .slider-thumbs-title {
        font-family: $avenir-heavy;
        font-size: 14px;
        line-height: calc(20 / 14);
        color: $dark-text;
        margin-bottom: 16px;
        text-transform: uppercase;
        @media (max-width: 768px) {
            padding-top: 32px;
        }
    }
    .slider-thumbs {
        display: flex;
        transform: translateX(-4px);
        margin-bottom: 144px;
        flex-wrap: wrap;
        @media (max-height: 950px) {
            margin-bottom: 96px;
        }
        @media (max-width: 1300px), (max-height: 700px) {
            margin-bottom: 56px;
        }
    }
    .thumb {
        background: transparent;
        margin-right: 24px;
        border: 4px solid #f9faf1;
        padding: 0;
        margin-bottom: 8px;
        img {
            height: 80px;
            display: block;
            @media (max-width: 768px) {
                height: 56px;
            }
        }
        &.active {
            border: 4px solid $magenta;
        }
        @media (max-width: 768px) {
            margin-right: 8px;
        }
    }
    .finish-info {
        .title {
            font-family: $avenir-heavy;
            font-size: 24px;
            color: $dark-text;
            letter-spacing: 0;
            line-height: calc(20 / 24);
            text-transform: uppercase;
            margin-bottom: 16px;
            @media (max-height: 950px) {
                font-size: 18px;
            }
        }
        .finish-copy {
            font-family: $avenir-book;
            font-size: 18px;
            color: $dark-text;
            letter-spacing: 0;
            line-height: calc(28 / 18);
            @media (max-height: 950px) {
                font-size: 14px;
            }
        }
    }
    .slider-right {
        padding-top: 48px;
        display: flex;
        padding-right: 48px;
        @media (max-width: 768px) {
            padding-right: 32px;
            margin-left: -32px;
        }
    }
    .finish-image-container {
        height: 85%;
        margin-top: 48px;
        transform: translateX(32px);
        box-shadow: 50px 59px 80px 0 rgba(0, 0, 0, 0.5);
        position: relative;
        z-index: 1;
        .finish-image {
            height: 100%;
            @media (max-width: 1300px), (max-height: 700px) {
                height: auto;
                width: 100%;
                display: block;
            }
        }
        @media (max-width: 1300px), (max-height: 700px) {
            height: auto;
            margin-left: 128px;
            width: 40% !important;
            flex-shrink: 1;
        }
        @media (max-width: 768px) {
            margin-left: 32px;
        }
    }
    .finish-rendering-container {
        height: 50%;
        @media (max-width: 1300px), (max-height: 700px) {
            height: auto;
            width: 100%;
            width: 100%;
            width: 60% !important;
            flex-shrink: 1;
        }
        .finish-rendering {
            height: 100%;
            @media (max-width: 1300px), (max-height: 700px) {
                height: auto;
                width: 100%;
                display: block;
            }
        }
    }
    .features-renderings-flowers-right {
        position: absolute;
        bottom: 0;
        right: 48px;
        height: 40%;
        mix-blend-mode: multiply;
        @media (max-width: 1300px), (max-height: 700px) {
            display: none;
        }
    }
}
