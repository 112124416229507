@import '../../App.scss';

// Local Variables

$header-offset: 192px;

// .floorplans-table {
//     grid-template-columns: repeat(8, auto);
// }

.table-viewport {
    height: calc(100vh - #{$header-offset});
    overflow-y: auto;
    cursor: grab;
    @media (max-width: 1300px) {
        height: calc(100vh - 168px);
    }
    .floorplans-table {
        display: grid;
        max-width: 1200px;
        padding: 0 16px;
        margin: 0 auto;
        // grid-template-columns: repeat(7, auto);
        grid-template-columns: repeat(5, auto);
        position: relative;
    }
    &.compare-table {
        height: calc(100vh - 144px);
        // overflow: hidden;
    }
    &.compare-table .floorplans-table {
        grid-template-columns: repeat(5, auto);
        // padding-top: 128px;
        > div {
            font-family: $avenir-medium;
            display: flex;
            align-items: center;
            font-size: 16px;
            line-height: calc(22 / 16);
            text-transform: uppercase;
            color: #003322;
            padding: 8px 8px 8px 0;
            border-bottom: 1px solid #e0e0e0;
            height: 100px;
        }
        .top-row {
            @supports (position: sticky) {
                position: sticky;
                // top: 128px;
            }
            height: 64px;
            color: #7a7367;
            text-transform: none;
            border-bottom: none;
            padding: 0;
            padding-right: 8px;
            z-index: 1;
        }
        .list-rendering {
            max-width: 264px;
        }
        .list-name {
            font-family: $avenir-heavy;
        }
        .star-container {
            justify-content: center;
            img {
                cursor: pointer;
            }
        }
        .right-arrow-container {
            justify-content: flex-end;
            padding-right: 0;
            img {
                cursor: pointer;
            }
        }
    }
}
