@import '../../App.scss';

.compare-floorplans-list-container {
    width: 100%;
    position: relative;
    .top-bar-bg {
        position: absolute;
        position: fixed;
        top: 272px;
        width: calc(50% - 12px);
        height: 64px;
        background: #f5f1eb;
        z-index: 1;
    }
}
.compare-floorplans-list-header-container {
    display: flex;
    height: 128px;
    padding-top: 64px;
    background: $dark-green;
    position: fixed;
    width: calc(50% - 12px);
    align-items: center;
    color: $white;
    padding: 64px 16px 0 16px;
    box-sizing: border-box;
    z-index: 1;
    padding-right: 0;
}
.compare-floorplans-list-header {
    @include serif;
    font-size: 32px;
    line-height: 1;
    flex-grow: 1;
}