@import '../../App.scss';

.star-icon * {
    cursor: pointer;
    stroke: inherit;
}
.active-star * {
    fill-opacity: 1;
    fill: inherit;
}
