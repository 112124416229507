@import '../../App.scss';

.accordion-item {
    background: $white;
    transition: all 0.5s;
    @include cubic;
    .down-arrow-path,
    svg {
        transition: all 0.25s;
        @include cubic;
    }
    .accordion-drop-down-container {
        height: 0;
        overflow: hidden;
        transition: all 0.5s;
        @include cubic;
    }
    &.active {
        background: #f4f5ec;
        .accordion-drop-down {
            opacity: 1;
        }
        svg {
            transform: scaleY(-1);
        }
    }
    .item-container {
        padding: 24px 32px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 13px;
        line-height: calc(18 / 13);
        text-transform: uppercase;
        letter-spacing: 0.87px;
        cursor: pointer;
        transition: all 0.25s;
        @include cubic;
        &:hover {
            color: $magenta;
            .down-arrow-path {
                stroke: $magenta;
            }
        }
    }
    .down-arrow-path {
        stroke: $dark-text;
    }
    .accordion-drop-down {
        font-size: 16px;
        line-height: calc(28 / 16);
        padding: 0 24px 32px;
        opacity: 0;
        transition: all 0.5s;
        @include cubic;
        .number {
            background: $magenta;
            width: 24px;
            height: 24px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            color: $white;
            font-size: 12px;
            margin-right: 16px;
            border-radius: 50%;
        }
        .accordion-drop-down-item {
            margin: 8px 0;
            display: flex;
            &:first-of-type {
                margin-top: 0;
            }
            &:last-of-type {
                margin-bottom: 0;
            }
        }
    }
}
