@import '../../App.scss';

// Local Variables

$header-offset: 80px;

.compare-floorplans {
    padding-top: $header-offset;
    background: $dark-green;
    .compare-floorplans-title {
        font-family: $avenir-medium;
        font-size: 16px;
        line-height: calc(22 / 16);
        letter-spacing: 0.74px;
        text-align: center;
        padding: 21px 16px;
        color: $white;
        text-transform: uppercase;
        position: relative;
        visibility: hidden;
    }
    .compare-floorplans-container {
        display: flex;
    }
}
