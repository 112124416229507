@import '../../App.scss';

.form-text-input {
    border: 1px solid $white;
    display: flex;
    box-sizing: border-box;
    margin-top: -1px;
    &:nth-child(odd) {
        margin-right: 24px;
        @media (max-width: 768px) {
            margin-right: 0;
        }
    }
    label {
        border-right: 1px solid $white;
        padding: 16px;
        width: 116px;
    }
    input {
        outline: none;
        border: none;
        background: #3e524b;
        flex: 1;
        padding: 16px;
        color: $white;
        text-transform: uppercase;
        font-family: $avenir-book;
        font-size: 12px;
        line-height: calc(16 / 12);
        letter-spacing: 0.8px;
        transition: background 0.25s;
        &.text-input-error {
            background: red;
        }
        @media (max-width: 1600px) {
            font-size: 10px;
        }
    }
    &.input-section-child {
        width: calc(50% - 12px);
        @media (max-width: 768px) {
            width: 100%;
            flex-direction: column;
            margin-bottom: 24px;
            border: none;
            label {
                border-right: none;
                padding: 8px 0;
            }
            input {
                border: 1px solid $white;
            }
        }
    }
    &.radio-section-child {
        margin-top: -50px;
        transition: all 0.25s;
        @include cubic;
        label {
            width: 184px;
            @media (max-width: 768px) {
                opacity: 0;
                transition: all 0.2s;
                @include cubic;
            }
        }
        &.active {
            margin-top: -1px;
            label {
                opacity: 1;
                // transition-delay: 0.1s;
            }
        }
        @media (max-width: 1600px) {
            margin-top: -47px;
        }
        @media (max-width: 768px) {
            width: 100%;
            flex-direction: column;
            margin-bottom: 24px;
            border: none;
            margin-top: -100px;
            label {
                border-right: none;
                padding: 8px 0;
            }
            input {
                border: 1px solid $white;
            }
        }
    }
}
