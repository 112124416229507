@import '../../App.scss';

.floorplan-details-send-email {
    position: fixed;
    top: 0;
    left: 0;
    opacity: 0;
    pointer-events: none;
    z-index: 9999;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &.open {
        opacity: 1;
        pointer-events: auto;
    }
}

.floorplan-details-send-email-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: #fff;
    opacity: 0.9;
}

.floorplan-details-send-email-wrapper {
    position: relative;
    min-width: 500px;
    padding: 56px 40px;
    box-sizing: border-box;
    background-color: $dark-green;
    display: flex;
    flex-direction: column;
    border: 1px solid #fff;
    @media (max-width: 768px) {
        min-width: 280px;
    }
    > label {
        font-size: 24px;
        line-height: calc(33 / 24);
        font-family: $avenir-heavy;
        text-transform: uppercase;
        letter-spacing: 0.87px;
        color: $white;
        text-align: center;
        margin-bottom: 32px;

        &.thank-you {
            margin-bottom: 0;
            padding-top: 24px;
            padding-bottom: 24px;
            text-transform: none;
        }
    }

    > .email-input {
        border: 1px solid $white;
        display: flex;
        box-sizing: border-box;

        > label {
            font-family: $avenir-book;
            border-right: 1px solid $white;
            padding: 16px;
            color: $white;
            font-size: 12px;
            line-height: calc(16 / 12);
            letter-spacing: 0.8px;
            text-transform: uppercase;

            .req-star {
                color: #ffe000;
            }
        }

        > input {
            outline: none;
            border: none;
            background-color: #3e524b;
            flex: 1;
            padding: 16px;
            color: $white;
            text-transform: uppercase;
            font-family: $avenir-book;
            font-size: 12px;
            line-height: calc(16 / 12);
            letter-spacing: 0.8px;
            transition: background 0.25s;

            &.text-input-error {
                background: red;
            }
        }
    }

    > button {
        height: 48px;
        border-radius: 0;
        border: 0;
        text-transform: uppercase;
        outline: none;
        background-color: #9b3465;
        color: $white;
        font-size: 12px;
        letter-spacing: 0.8px;
        transition: all 0.25s;
        cursor: pointer;
        border-top: none;
        display: flex;
        justify-content: center;
        align-items: center;
        @include cubic;
        box-sizing: border-box;
        margin-top: 16px;
        &:hover {
            background-color: $white;
            color: $dark-text;
        }
    }
    .email-close-button {
        position: absolute;
        top: 16px;
        right: 16px;
        cursor: pointer;
        svg {
            display: block;
            transition: all 0.25s;
        }
        &:hover {
            svg {
                transform: rotate(90deg);
            }
        }
    }
}
