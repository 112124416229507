@import '../../App.scss';

// Local Variables

$header-offset: 144px;
$mobile-header-offset: 120px;

.gallery-area-viewport {
    height: 100vh;
    overflow-y: hidden;
    cursor: grab;
    @media (max-width: 1300px) {
        height: auto;
        cursor: default;
    }
    .gallery-area {
        padding-top: calc(#{$header-offset} + 72px);
        padding-left: 48px;
        padding-right: 48px;
        height: 100vh;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        flex-wrap: wrap;
        box-sizing: border-box;
        .drag-info {
            @media (max-width: 1300px) {
                display: none;
            }
        }
        @media (max-width: 1300px) {
            padding-top: calc(#{$mobile-header-offset} + 48px);
            height: auto;
            flex-direction: row;
            padding-left: 24px;
            padding-right: 24px;
        }
        .area-module {
            margin-right: 24px;
            margin-bottom: 24px;
            height: calc((100vh - 288px) / 2);
            @media (max-width: 1300px) {
                width: calc(50% - 12px);
                height: auto;
                &:nth-child(even) {
                    margin-right: 0;
                }
            }
            @media (max-width: 768px) {
                width: 100%;
                margin-right: 0;
                display: flex;
                flex-direction: column-reverse;
            }
        }
        .area-image-container {
            overflow: hidden;
            display: flex;
            justify-content: center;
            height: calc(100% - 80px);
            img {
                height: 100%;
                @media (max-width: 1300px) {
                    width: 100%;
                    object-fit: cover;
                }
            }
        }
        .area-caption {
            font-family: $avenir-heavy;
            color: $dark-text;
            text-align: center;
            font-size: 14px;
            line-height: calc(24 / 14);
            margin: 16px 0 16px;
            text-transform: uppercase;
        }
    }
}
