@import '../../App.scss';

// Local Variables

$header-offset: 192px;

.grid-floorplan {
    border: 1px solid #e0e0e0;
    margin-left: -1px;
    margin-top: -1px;
    width: calc(25% + 1px);
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
    text-align: center;
    height: calc(100vh - #{$header-offset});
    position: relative;
    cursor: pointer;
    @media (max-width: 1850px) {
        width: calc((100% / 3) + 1px);
    }
    @media (max-width: 1400px) {
        width: calc(50% + 1px);
    }
    @media (max-width: 1300px) {
        height: calc(100vh - 168px);
    }
    @media (max-width: 950px) {
        width: calc(100% + 1px);
    }
    @media (max-height: 1000px) {
        height: auto;
    }
    .grid-floorplan-caption {
        font-size: 16px;
        line-height: calc(24 / 16);
        font-family: $avenir-heavy;
        color: $dark-text;
        text-transform: uppercase;
        position: absolute;
        bottom: 24px;
        width: 100%;
    }
}
