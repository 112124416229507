@import '../../App.scss';

.floorplan-details-info-panel {
    border: 1px solid #e0e0e0;
    width: 25%;
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
    text-align: center;
    position: relative;
    // .tabbed-container {
    //     justify-content: center;
    //     .floorplan-details-stats {
    //         padding-top: 8px;
    //     }
    // }
    &.compare-info-panel {
        width: 50%;
        .tabbed-titles {
            display: none;
        }
        @media (max-width: 1900px) {
            .floorplan-details-info {
                padding: 0 8px;
                font-size: 12px;
            }
            .main-button {
                width: 280px !important;
            }
        }
    }
    .floorplan-details-caption {
        padding-bottom: 40px;
        font-size: 16px;
        line-height: calc(24 / 16);
        font-family: $avenir-heavy;
        color: $dark-text;
        text-transform: uppercase;
    }
    .back-to-floorplans {
        position: absolute;
        bottom: 200px;
        width: 100%;
        text-align: center;
        padding: 0px 8px;
        color: #7b7366;
        text-transform: uppercase;
        font-family: $avenir-book;
        font-size: 13px;
        line-height: calc(18 / 13);
        letter-spacing: 0.87px;
        display: flex;
        transition: all 0.25s;
        @include cubic;
        &:hover {
            color: $magenta;
            .line {
                fill: $magenta;
            }
            .arrow {
                stroke: $magenta;
            }
        }
        svg {
            margin-right: 16px;
        }
    }
    .flowers-left {
        position: absolute;
        bottom: 0;
        left: 0;
        max-height: 160px;
        max-width: 40%;
    }
    .flowers-right {
        position: absolute;
        bottom: 0;
        right: 0;
        max-height: 160px;
        max-width: 40%;
    }
    &.site-plan-info-panel {
        width: 50%;
        @media (max-width: 1600px) {
            width: 100%;
            border: none;
            padding-bottom: 40px;
        }
        @media (max-width: 768px) {
            .main-button {
                width: 264px !important;
            }
        }
    }
}
