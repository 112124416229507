@import '../../App.scss';

// Local Variables

$top-bottom-padding: 56px;

.accordion {
    padding: $top-bottom-padding 0 $top-bottom-padding 16px;
    display: inline-block;
    transition: all 0.25s;
    @include cubic;
    flex-shrink: 0;
    @media (max-width: 1300px) {
        padding-right: 24px;
    }
}
