@import '../../App.scss';

// Local Variables

$header-offset: 80px;
$mobile-header-offset: 56px;

.site-plan {
    padding-top: $header-offset;
    height: calc(100vh - #{$header-offset});
    width: 100vw;
    display: flex;
    @media (max-width: 1300px) {
        padding-top: $mobile-header-offset;
        height: calc(100vh - #{$mobile-header-offset});
        flex-direction: column;
    }
    .responsive-container {
        display: flex;
        position: relative;
        @media (max-width: 1300px) {
            flex-direction: column-reverse;
        }
    }
    .side-bar {
        width: 192px;
        height: 100%;
        background: #fff;
        z-index: 1;
        position: relative;
        display: flex;
        flex-direction: column;
        @media (max-width: 1300px) {
            width: 100%;
            display: flex;
            flex-direction: column-reverse;
            .main-button {
                width: auto !important;
                display: inline-flex !important;
                flex-direction: row !important;
                margin-top: 40px;
            }
        }
        svg {
            margin-right: 0;
            margin-bottom: 8px;
            @media (max-width: 1300px) {
                margin-bottom: 0;
                margin-right: 8px;
            }
        }
        .legend {
            padding: 32px;
            overflow: auto;
        }
        h2 {
            font-family: $avenir-heavy;
            color: $dark-text;
            font-size: 14px;
            line-height: calc(24 / 14);
            margin-bottom: 16px;
            text-transform: uppercase;
        }
        ul {
            font-family: $avenir-roman;
            color: $dark-text;
            font-size: 14px;
            line-height: calc(19 / 14);
            margin-bottom: 48px;
            @media (max-width: 1300px) {
                column-count: 3;
            }
            &:last-of-type {
                margin-bottom: 0;
            }
            li {
                margin-bottom: 8px;
                display: flex;
                align-items: center;
                cursor: pointer;
            }
        }
        .color-box {
            display: inline-block;
            width: 16px;
            height: 16px;
            border: 1px solid $dark-text;
            margin-right: 8px;
            background: pink;
        }
    }
    .site-plan-viewport {
        overflow: hidden;
        position: relative;
        height: 100%;
        width: calc(100vw - 176px);
        flex: 1;
        background: $dark-green;
        cursor: grab;
        @media (max-width: 1300px) {
            display: none;
        }
        .loading-icon {
            stroke: $magenta;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate3d(-50%, -50%, 0);
            transition: all 1s;
            @include cubic;
        }
        .site-plan-image-container {
            position: relative;
            height: calc((100vw - 175px) * 0.6616);
            width: calc(100vw - 175px);
            @media (max-width: 1532px) {
                height: calc(100vh - #{$header-offset});
                width: calc((100vh - #{$header-offset}) / 0.6616);
            }

            @media (max-width: 1300px) {
                height: calc(100vh - #{$mobile-header-offset});
                width: calc((100vh - #{$mobile-header-offset}) / 0.6616);
            }
            .site-plan-jpg {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translateX(-50%) translateY(-50%);
                width: 100%;
                height: 100%;
            }
            .site-plan-svg {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translateX(-50%) translateY(-50%);
                width: 100%;
                height: 100%;
            }
        }
    }
    .zoom-container {
        position: absolute;
        bottom: 24px;
        right: 24px;
        width: 56px;
        height: 56px;
        background: $magenta;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        transition: all 0.25s;
        @include cubic;
        border: 1px solid $magenta;
        .magnifier,
        .plus {
            transition: all 0.25s;
        }
        &:hover {
            color: $magenta;
            background: $white;
            border: 1px solid $magenta;
            .magnifier {
                stroke: $magenta;
            }
            .sign {
                fill: $magenta;
            }
        }
        &.zoom-in-container {
            bottom: 88px;
        }
    }
    .site-plan-floorplan-details {
        position: fixed;
        width: 50%;
        height: calc(100% - 80px);
        top: 80px;
        left: calc(-50% - 56px);
        z-index: 2;
        background: $white;
        display: flex;
        transition: all 0.5s;
        @include cubic;
        .floorplan-details-info {
            @media (max-width: 768px) {
                padding: 0 8px;
                font-size: 12px;
            }
        }
        @media (max-width: 1600px) {
            flex-direction: column;
            overflow-y: auto;
            overflow-x: hidden;
            .flowers-left,
            .flowers-right {
                display: none;
            }
        }
        @media (max-width: 1300px) {
            top: 56px;
            height: calc(100% - 56px);
            .grid-floorplan-image-container {
                height: auto;
            }
        }
        @media (max-width: 768px) {
            width: 296px;
            left: -296px;
            .floorplan-details-tab {
                margin: 0 12px;
            }
        }
        .floorplan-details-info-panel {
            .floorplan-details-tab {
                display: none;
            }
        }
        &.site-plan-floorplan-details-open {
            left: 0;
        }
        .site-plan-close-floorplan {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: -56px;
            background: $magenta;
            width: 56px;
            height: 56px;
            transition: all 0.25s;
            @include cubic;
            @media (max-width: 1600px) {
                top: 0;
                right: 0;
                transform: none;
            }
            svg {
                .close-fill {
                    fill: $white;
                    transition: all 0.25s;
                    @include cubic;
                }
            }
            &:hover {
                background: $white;
                svg {
                    .close-fill {
                        fill: $magenta;
                    }
                }
            }
        }
    }
    .mobile-site-plan-image-container {
        display: none;
        img {
            width: 100%;
        }
        svg {
            width: 100%;
            position: absolute;
            top: 0;
            left: 0;
            height: auto;
        }
        @media (max-width: 1300px) {
            display: block;
            svg {
                pointer-events: none;
            }
        }
    }
}
