@import '../../App.scss';

// Local Variables

$header-offset: 80px;
$mobile-header-offset: 56px;

.home-viewport {
    height: 100%;
    overflow-x: auto;
    display: flex;
    cursor: grab;
    @media (max-width: 1024px) {
        height: auto;
        cursor: default;
    }
}

.home {
    padding-top: $header-offset;
    height: calc(100% - #{$header-offset});
    display: flex;
    @media (max-width: 1300px) {
        padding-top: $mobile-header-offset;
        height: calc(100% - #{$mobile-header-offset});
    }
    .drag-info {
        top: calc(50% + 40px);
    }
    .home-fullscreen {
        display: flex;
        width: 100vw;
        position: relative;

        .desktop-banner-image {
            position: absolute;
            top: 0;
            left: 0;
            height: 160px;
            width: auto;
            display: block;
        }
        .mobile-banner-image {
            position: absolute;
            top: 0;
            left: 0;
            height: 160px;
            width: auto;
            display: none;
        }

        .home-fullscreen-image-container {
            // padding: 116px;
            display: flex;
            align-items: center;
            justify-content: center;
            // background: #2d3d38;
            flex-grow: 1;
            position: relative;
            img {
                width: 100%;
            }

            .wrapper {
                position: relative;
    
                .banner {
                    width:25%;
                    height: auto;
                    top: 0;
                    left: 0;
                    position: absolute;
                    display: block;
                }
            }

            .disclaimer {
                position: absolute;
                bottom: 40px;
                right: 40px;
                width: 400px;
                text-align: right;
                font-family: $avenir-book;
                font-size: 8px;
            }
        }

        @media (max-width: 1024px) {
            flex-direction: column;
            width: 100%;

            .mobile-banner-image {
                display: block;
            }

            .desktop-banner-image {
                display: none;
            }

            .home-fullscreen-image-container .wrapper .banner {
                display: none;
            }
        }


        &.home-fullscreen-green {
            background: $dark-green;
        }
    }
    .home-hero {
        background: no-repeat center center / cover;
        padding: 136px 80px 0;
        box-sizing: border-box;
        color: $dark-text;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        @media (max-height: 950px) {
            padding-top: 80px;
        }
    }
    .home-hero-title {
        @include serif;
        font-size: 72px;
        line-height: 1;
        margin-bottom: 64px;
        @media (max-height: 950px) {
            font-size: 56px;
            margin-bottom: 40px;
        }
        span {
            font-size: 48px;
            display: block;
        }
    }
    .home-hero-tagline {
        font-family: $avenir-book;
        font-size: 18px;
        line-height: 28px;
        margin-bottom: 48px;
        @media (max-height: 950px) {
            font-size: 16px;
            margin-bottom: 32px;
        }
    }
    .home-hero-price {
        font-family: $avenir-heavy;
        font-size: 20px;
        line-height: 24px;
        text-transform: uppercase;
        span {
            text-transform: lowercase;
        }
    }
    .home-hero-flex {
        width: 400px;
    }
    .home-hero-flex-image {
        justify-content: flex-end;
        margin-top: 80px;
        flex: 1;
        position: relative;
        width: 400px;
        @media (max-height: 950px) {
            margin-top: 48px;
        }

        img {
            max-height: 100%;
            max-width: 100%;
            position: absolute;
            max-height: 100%;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            mix-blend-mode: multiply;
        }
    }
    .full-height-image {
        height: 100%;
        // width: 100%;
    }
    .home-content-1 {
        padding: 80px 88px;
        box-sizing: border-box;
        color: $white;
        text-align: center;
        height: 100%;
        background: $dark-green;
        display: flex;
        flex-direction: column;
        justify-content: center;
        @media (max-height: 950px) {
            padding: 40px 88px;
        }
        .container {
            width: 472px;
        }
        .top {
            @include serif;
            font-size: 32px;
            line-height: calc(40 / 32);
            @media (max-height: 950px) {
                font-size: 24px;
            }
        }
        .number {
            @include serif;
            font-size: 320px;
            font-style: normal;
            font-weight: 300;
            line-height: 1;
            margin-top: -80px;
            margin-bottom: 32px;
            @media (max-height: 950px) {
                font-size: 160px;
                margin-top: -40px;
                margin-bottom: 16px;
            }
        }
        .bottom {
            @include serif;
            font-size: 40px;
            line-height: calc(52 / 40);
            margin-bottom: 40px;
            @media (max-height: 950px) {
                font-size: 32px;
                margin-bottom: 24px;
            }
        }
        .copy {
            font-family: $avenir-book-oblique;
            font-size: 18px;
            line-height: calc(28 / 18);
            margin-bottom: 40px;
            @media (max-height: 950px) {
                font-size: 14px;
            }
        }
    }
    .home-content-2 {
        padding: 80px;
        box-sizing: border-box;
        color: $white;
        text-align: center;
        height: 100%;
        background: $dark-green;
        display: flex;
        .container {
            width: 472px;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
        .title {
            @include serif;
            font-size: 48px;
            line-height: calc(52 / 48);
            margin-bottom: 40px;
            @media (max-height: 950px) {
                font-size: 40px;
                margin-bottom: 24px;
            }
        }
        .copy {
            font-family: $avenir-book;
            font-size: 18px;
            line-height: calc(28 / 18);
            margin-bottom: 40px;
            @media (max-height: 950px) {
                font-size: 16px;
            }
        }
    }
    .home-content-3 {
        padding: 80px 88px;
        box-sizing: border-box;
        color: $dark-text;
        height: 100%;
        background: #d8ecf0;
        display: flex;
        .container {
            width: 472px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
        }
        .title {
            @include serif;
            font-size: 64px;
            line-height: 1;
            margin-bottom: 40px;
            @media (max-height: 950px) {
                font-size: 48px;
                margin-bottom: 24px;
            }
        }
        .copy {
            font-family: $avenir-book;
            font-size: 18px;
            line-height: calc(28 / 18);
            margin-bottom: 40px;
            @media (max-height: 950px) {
                font-size: 16px;
                margin-bottom: 24px;
            }
        }
    }
    @media (max-width: 1024px), (max-height: 700px) {
        display: block;
        .drag-info {
            display: none;
        }
        .home-hero {
            width: 100%;
            align-items: center;
            height: auto;
            padding-left: 24px;
            padding-right: 24px;
            padding-top: 80px;
        }
        .home-hero-flex {
            width: auto;
            max-width: 400px;
        }
        .home-hero-flex-image {
            img {
                position: static;
                transform: none;
                height: 300px;
            }
        }
        .full-height-image {
            height: auto;
            width: 100%;
            display: block;
        }
        .home-content-1 {
            align-items: center;
            height: auto;
            padding: 40px 24px;
            .container {
                width: 100%;
                max-width: 472px;
            }
            .main-button {
                max-width: 368px;
                width: auto !important;
            }
        }
        .home-content-2 {
            justify-content: center;
            height: auto;
            padding: 40px 24px;
            .container {
                width: 100%;
                max-width: 472px;
            }
            .main-button {
                max-width: 368px;
                width: auto !important;
                &.svg-absolute {
                    svg {
                        position: static;
                        transform: none;
                    }
                }
            }
        }
        .home-content-3 {
            justify-content: center;
            height: auto;
            padding: 40px 24px;
            .container {
                width: 100%;
                max-width: 472px;
            }
            .main-button {
                max-width: 368px;
                width: auto !important;
            }
        }
        .home-hero-flex-image {
            width: auto;
        }
    }
    @media (max-width: 768px) {
        .home-hero-flex-image {
            img {
                height: 200px;
            }
        }
    }
}
