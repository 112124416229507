@import '../../App.scss';

// Local Variables

$header-offset: 80px;
$mobile-header-offset: 56px;

.contact-bg {
    background: $dark-green;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
}
.contact {
    padding-top: $header-offset;
    background: $dark-green;
    font-family: $avenir-book;
    min-height: calc(100vh - #{$header-offset});
    display: flex;
    color: $dark-green;
    @media (max-width: 1300px) {
        padding-top: $mobile-header-offset;
        min-height: calc(100vh - #{$mobile-header-offset});
        flex-direction: column;
        height: auto;
    }
    h2 {
        margin: 56px 0 32px;
        font-size: 40px;
        line-height: calc(48 / 40);
        @include serif;
        color: $dark-green;
        text-align: center;
        @media (max-width: 1600px) {
            font-size: 32px;
        }
        @media (max-height: 950px) {
            font-size: 32px;
        }
    }
    .contact-info-container {
        min-height: calc(100vh - #{$header-offset});
        width: 648px;
        background: $white;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: fixed;
        bottom: 0;
        top: $header-offset;
        @media (max-width: 1300px) {
            width: 100%;
            min-height: 0;
            top: $mobile-header-offset;
            position: relative;
            top: 0;
        }
    }
    .contact-info {
        width: 344px;
        padding: 0 16px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        height: 100%;
        img {
            width: 100%;
            @media (max-width: 1300px) {
                margin-bottom: 40px;
            }
        }
        .flex-section {
            display: flex;
            flex-direction: column;
            align-items: center;
            @media (max-width: 768px) {
                width: calc(320px - 32px);
                .main-button {
                    max-width: 100%;
                    font-size: 10px;
                }
            }
        }
    }
    .contact-form-container {
        flex: 1;
        display: flex;
        flex-direction: column;
        width: calc(100vw - 648px);
        padding-left: 648px;
        box-sizing: border-box;
        margin-right: calc(100% - 100vw);
        h2 {
            color: $white;
            padding: 0 16px;
            @media (max-width: 768px) {
                font-size: 24px;
            }
        }
        @media (max-width: 1300px) {
            margin-bottom: 40px;
            width: 100%;
            padding-left: 0;
        }
    }
    .flex-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex: 1;
        max-width: 880px;
        margin: 0 auto;
        padding: 0 24px;
    }
    .contact-bottom {
        color: $white;
        display: flex;
        justify-content: space-between;
        font-size: 14px;
        line-height: calc(19 / 14);
        margin-bottom: 48px;
        margin-top: 96px;
        @media (max-width: 768px) {
            flex-direction: column-reverse;
        }
        span {
            font-size: 12px;
            margin-left: 48px;
        }
        a {
            text-transform: uppercase;
            color: #f49d6e;
            transition: all 0.25s;
            @include cubic;
            &:hover {
                color: #ffe000;
            }
        }
        .links {
            min-width: 300px;
            @media (max-width: 768px) {
                margin-bottom: 40px;
                span {
                    &:first-of-type {
                        margin-left: 0;
                    }
                }
            }
        }
    }
    .contact-link {
        color: $magenta;
        text-decoration: underline;
        font-size: 18px;
        line-height: calc(24 / 18);
        margin-bottom: 24px;
        transition: all 0.25s;
        @include cubic;
        &:hover {
            color: $dark-text;
        }
    }
    .contact-phone {
        color: $dark-text;
        font-size: 18px;
        line-height: calc(24 / 18);
        margin-bottom: 16px;
        transition: all 0.25s;
        @include cubic;
        &:hover {
            color: $magenta;
        }
    }
    .sales-office-title {
        @include serif;
        font-size: 22px;
        line-height: calc(24 / 22);
        margin-bottom: 8px;
    }
    .address {
        font-size: 18px;
        line-height: calc(24 / 18);
        margin-bottom: 8px;
    }
    .contact-social {
        display: flex;
        margin: 24px 0;
        &.contact-social-desktop {
            @media (max-width: 1300px) {
                display: none;
            }
        }
        li {
            margin-right: 24px;
            &:last-of-type {
                margin-right: 0;
            }
        }
        svg {
            &:hover {
                path {
                    fill: $dark-text;
                }
            }
        }
        svg,
        path {
            fill: $magenta;
            transition: all 0.25s;
            @include cubic;
        }
    }
    .corporate-logos {
        margin-bottom: 48px;
        &.corporate-logos-desktop {
            @media (max-width: 1300px) {
                display: none;
            }
        }
        a {
            &:first-of-type {
                margin-right: 24px;
            }
            &:last-of-type {
                margin-left: 24px;
            }
        }
        img {
            height: 40px;
            width: auto;
        }
        .separator {
            display: inline-block;
            width: 1px;
            height: 40px;
            background: #6c6e70;
        }
    }
    .contact-flowers-left {
        position: absolute;
        bottom: 0;
        left: 0;
        width: calc((100% - 392px) / 2);
        @media (max-width: 768px) {
            display: none;
        }
    }
    .contact-flowers-right {
        position: absolute;
        bottom: 0;
        right: 0;
        width: calc((100% - 392px) / 2);
        @media (max-width: 768px) {
            display: none;
        }
    }
}
